import React from "react";
import projectmgr from "../../../Assets/aboutUs/project mgr.jpg";
import ContentCard from "../../../Components/ContentCard/ContentCard";
import UseMediaQueryHook from "../../../Hooks/mediaQuery";
import { useMobile } from "../../../Hooks/useMobile";
import dec1 from "./projectManagerBackgroundPhotos/dec1.png";
import dec2 from "./projectManagerBackgroundPhotos/dec1.png";
import dec3 from "./projectManagerBackgroundPhotos/dec1.png";
import photoTablet from "./projectManagerBackgroundPhotos/photoTablet.png";
import tabletPencil from "./projectManagerBackgroundPhotos/tabletPencil.png";

const ProjectManagment = () => {
  const [specialQuery] = UseMediaQueryHook(1000, 1200, 1280);
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  return (
    <div
      className={`flex max-w-[1280px] w-fit m-auto pl-10 pr-10 justify-center pt-10 pb-10 ${
        specialQuery == 1000 && "flex-col max-w-none"
      }
      
      `}
    >
      <div className="absolute">
        <img
          src={dec1}
          className={`
        ${isDesktopLow && "relative left-[-520px] -z-10 bottom-[-150px]"}
        ${isTablet && "hidden"}
        ${isMobile && "hidden"}
        `}
        />
      </div>
      <div className="absolute">
        <img
          src={dec2}
          className={`
        ${isDesktopLow && "absolute left-[650px] bottom-[-150px]"}
        ${isTablet && "absolute left-[500px] bottom-[-150px]"}
        ${isMobile && "hidden"}
        `}
        />
      </div>
      <div className="absolute">
        <img
          src={dec3}
          className={`
        ${isDesktopLow && "relative left-[-153px] -z-10 top-[-150px]"}
        ${isTablet && "relative left-[-100px] -z-10  top-[-150px]"}
        ${isMobile && "hidden"}
        `}
        />
      </div>
      <div className="relative">
        <img
          src={photoTablet}
          className={`w-[500px] ${
            specialQuery == 1000 && "w-[620px] h-auto m-auto object-scale-down"
          }`}
        />
        <div className="absolute">
        <img className={` relative 
        ${isDesktopLow&&"bottom-96 left-72"}
        ${isTablet&&"bottom-96 left-96"}
        ${isMobile&&"bottom-[352px] left-[257px]"}
        `} src={tabletPencil}></img>
        </div>
      </div>
      <div className=" flex z-20">
        <ContentCard
          // colorTheme={"orange"}
          headerTopTitle={"Viante Konstruksion"}
          headerTitle={"Partneri juaj më i mirë"}
          Content={`Nga rikonstruksioni tek ndërtimi nga themeli, Viante Konstruksion është i apasionuar pas krijimit të një përvoje ndërtimi  shpërblyese për klientët ,projektuesit ,ekipin dhe partnerët tanë.`}
          buttonTitle={"Na kontaktoni"}
          linkTo={"/contactUs"}
          contentSize={20}
        />
      </div>
    </div>
  );
};

export default ProjectManagment;
