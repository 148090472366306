import React from "react";
import FullProjectMgrCard from "./FullProjectMgrCard";
import sircle from "../../../../Assets/OurServices/FullProjectManagment/sircke.png";
import cicleOverlap from "../../../../Assets/OurServices/FullProjectManagment/cicleOverlap.png";
import qube from "../../../../Assets/OurServices/FullProjectManagment/qube.png";
import square from "../../../../Assets/OurServices/FullProjectManagment/square.png";
import stack from "../../../../Assets/OurServices/FullProjectManagment/stack.png";
import { useMobile } from "../../../../Hooks/useMobile";

const ProjectmanagmentPartTitle = ({subtitle,title}) => {
  return (
    <>
      <div className="text-gray-400">
        <span className={`font-bold text-gray-700 text-sm`}>
          {subtitle}
        </span>
        <h1 className={`font-bold text-BlueTheme text-[60px] w-3/4 leading-[56px]`}>
          {title}
        </h1>
      </div>
    </>
  );
};

const FullProjectManagmint = () => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();

  return (
    <div
      className={`grid px-4 gap-4  max-w-[1500px] m-auto
    ${isTablet && "grid-cols-1 w-[500px]"}
    ${isDesktopLow && "grid-cols-3"}
    `}
    >
      <ProjectmanagmentPartTitle subtitle={""} title={"Fazat e menaxhimit të projektit"} />
      <FullProjectMgrCard
        photo={sircle}
        subtitle={"FAZA I"}
        title={"Analiza e detyrës së projektimit"}
        info={`
        Menaxheri i projektit shkruan dokumentin e fillimit të projektit (PID), i cili përfshin si nevojat e biznesit ashtu edhe rastin e biznesit. Vlersimi I rastit të biznesit për të përcaktuar fizibilitetin e projektit. 
        `}
      />
      <FullProjectMgrCard
        photo={cicleOverlap}
        subtitle={"FAZA II"}
        title={"Projekt-ideja"}
        info={
          "Zhvillimi i mëtejshëm i projektit në detaje për të përmbushur objektivat,identifikimi i detyrave të projektit dhe kërkesat për burime së bashku me strategjinë për sigurimit të tyre."
        }
      />
      <FullProjectMgrCard
        photo={qube}
        subtitle={"FAZA III"}
        title={"Miratimi i lejes së ndërtimit"}
        info={
          "Kjo fazë është projektuar kryesisht për të verifikuar që aplikanti ka zhvilluar një program adekuat të sigurimit të cilësisë për projektimin dhe aktivitetet e prokurimit gjatë fazave të hershme të projektit ."
        }
      />
      <FullProjectMgrCard
        photo={square}
        subtitle={"FAZA IV"}
        title={"Projekti i zbatimit"}
        info={
          "Kjo është faza e zbatimit, ku plani i projektit vihet në lëvizje dhe puna e projektit kryhet praktikisht në terren. Progresi duhet të monitorohet vazhdimisht dhe rregullimet e duhura të bëhen dhe të regjistrohen në përputhje me planin origjinal."
        }
      />
      <FullProjectMgrCard
        photo={stack}
        subtitle={"FAZA V"}
        title={"Preventivi përfundimtar"}
        info={
          "Kjo fazë shënon përfundimin e projektit. Për të shënuar përfundimin, menaxherët e projektit realizojnë dokumentimin dhe raportet përfundimtare."
        }
      />
    </div>
  );
};

export default FullProjectManagmint;
