import React, { useEffect, useRef, useState } from "react";
import UseMediaQueryHook from "../../../Hooks/mediaQuery";
import useOnScreen from "../../../Hooks/useOnScreen";
import useRangedMediaQuery from "../../../Hooks/useRangedMediaQuery";
import NumberBarPart from "./NumberBarPart";

const NumberCardProgres = ({
  number,
  title,
  titleBreak,
  Info,
  titleAndNumberColor,
  percentP,
}) => {
  const [specialHook] = UseMediaQueryHook(100, 1057, 1285);
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [low,high]=useRangedMediaQuery(380,400)
  return (
    <div className={`w-[500px] h-56`}>
      <div className="relative text-gray-400 left-[50px] top-[20px]">
        <div className={`absolute ${low&&"scale-[0.75] -left-5"}`}>
          <div className="h-0 w-0 relative top-[4.7rem] left-[7.95rem] font-semibold">
            {title && <span className={` absolute`}>{title}</span>}
          </div>
          <div className="h-0 w-0 relative top-[5.67rem] left-[7.95rem] text-4xl font-medium">
            {titleBreak && (
              <span
                style={{ color: titleAndNumberColor }}
                className={` absolute text-2xl sm:text-4xl lg:text-3xl xl:text-4xl`}
              >
                {titleBreak}
              </span>
            )}
          </div>
          <div className="h-0 w-0 relative top-[143px] left-[7.95rem] text-sm font-semibold">
            {Info && (
              <span
                style={{}}
                className={` absolute max-h-[64px] overflow-clip break-words
                ${specialHook == 1057 ? "w-[10.5rem]" : "w-[170px]"}
                `}
              >
                {Info}
              </span>
            )}
          </div>
        </div>
      </div>

      <div
        className={`
        h-0 w-0 relative top-[4rem] left-[0rem] text-[60px] font-bold
        ${low&&"scale-[0.75]"}
        `}
        ref={ref}
      >
        {isVisible && (
          <span style={{ color: titleAndNumberColor }} className={` absolute`}>
            <NumberBarPart percent={percentP} />
          </span>
        )}
      </div>
    </div>
  );
};

export default NumberCardProgres;
