import React, { useState } from "react";
import { useMobile } from "../../../../Hooks/useMobile";
import facebook from "./testIcons/facebook.svg";
import facebookOrange from "./testIcons/facebookOrange.svg";
import contactData from "../../../../Data/contactData/ContactInfo"; 
import contactsMedia from "../../../../Data/contactData/ContactMedia/ContactMedia";

const ContactLogo = ({ logo, lohoHover,linkTo }) => {
  const [hover, setHover] = useState();
  return (
    <a href={linkTo}>
      <img
        className="w-5 h-5"
        src={hover ? lohoHover : logo}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
    </a>
  );
};

const ContactInfo = () => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  return (
    <div className={`bg-darkBlueTheme pl-20 pr-36 pt-20 pb-40 text-white h-full
    ${isDesktopLow&&"pl-20 pr-36"}
    ${isTablet&&"pl-20 pr-36"}
    ${isMobile&&"pl-2 pr-2"}
    `}>
      <div className="">
        <span className={`font-bold  text-sm`}>NDËRTONI ME VIANTE</span>
        <h1 className={`font-bold  text-5xl`}>Mos hezitoni të na kontaktoni!</h1>
      </div>
      <br />
      <br />
      <div className="address">
        <a href={contactData.location.linkToInfo}>{contactData.location.location}</a>
      </div>
      <br />
      <br />
      <div className="contact">
        <div className="">
          <span className="mr-2">Email:</span>
          <a className="hover:text-orangeTheme" href={`mailto:${contactData.EmailAddress.Email1}`}>{contactData.EmailAddress.Email1}</a>
        </div>
        <div >
          <span className="mr-2">Tel:</span>
          <a className="hover:text-orangeTheme" href={`tel:${contactData.PhoneNumber.Pone1}`}>{contactData.PhoneNumber.Pone1}</a>
        </div>
        <div>
          <span className="mr-2">Follow:</span>
          <a className="hover:text-orangeTheme" href={contactsMedia[0].url}>{contactsMedia[0].name}</a>
        </div>
      </div>
      <br />
      <br />
      {/* <div className="media">
        <h1 className="text-2xl">Follow us on:</h1>
        <br />
        <div className="flex w-[70%] gap-[10px]">
          <ContactLogo logo={contactsMedia[0].icon} lohoHover={contactsMedia[0].iconHover} linkTo={contactsMedia[0].url}/>
          <ContactLogo logo={facebook} lohoHover={facebookOrange} />
          <ContactLogo logo={facebook} lohoHover={facebookOrange} />
          <ContactLogo logo={facebook} lohoHover={facebookOrange} />
        </div>
      </div> */}
    </div>
  );
};

export default ContactInfo;
