import React from "react";
import { Link } from "react-router-dom";

const ButtonN2Link = ({
  children,
  backgroundColor,
  linkTo,
  color,
}) => {
  return (
    <Link
      to={linkTo}
      style={{ backgroundColor: backgroundColor,color:color }}
      className={`p-6 w-40 flex justify-center align-middle pt-2 pb-2 rounded-sm hover:shadow-xl 


      cursor-pointer
      
      hover:shadow-[${backgroundColor}]`}
    >
      {children}
    </Link>
  );
};

export default ButtonN2Link;
