import React from "react";
import FetureSection from "./sections/fetures/FetureSection";

import Numbers from "../../Components/Cards/NumberCard/NumberCard";
import FeedbackAndNumbers from "../Home/sections/feedbackAndNumbers/FeedbackAndNumbers";
import ProjectsSectionHome from "./sections/ProjectsSectionHome/ProjectsSectionHome";
import HomeHero from "./sections/hero/HomeHero";
import useRangedMediaQuery from "../../Hooks/useRangedMediaQuery";
import { useMobile } from "../../Hooks/useMobile";
import ServiceSectionHome from "../../Components/SharedSections/services/ServiceSectionHome";
import ProjectManagmentSection from "./sections/projectmanagment/ProjectManagmentSection";

const Home = () => {


  return (
    <>
      <div className="overflow-hidden">
        <HomeHero />
        <br />
        <br />
        <ServiceSectionHome />
        <br />
        <br />
        <ProjectManagmentSection />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <ProjectsSectionHome />
        <br />
        <br />
        <br />
        <br />
        <FetureSection />
        <br />
        <br />
        <br />
        <br />
        <FeedbackAndNumbers />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default Home;
