import emailjs from "@emailjs/browser";

export const emailService = async (payload) => {
  const sendEmail = () => {
    return new Promise((resolve, reject) => {
      emailjs
        .send(
          "service_2pdv4hu",
          "template_oze1tyi",
          payload,
          "aOju3dPV67mdZUHvG"
        )
        .then(
          (result) => {
            resolve(true);
          },
          (error) => {
            reject(false);
          }
        )
        .catch((n) => reject(false));
    });
  };

  try {
    await sendEmail();
    return true;
  } catch {
    return false;
  }
};
