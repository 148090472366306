import React, { useState } from "react";
import FeturePartFetured from "./FeturePartFetured";
import FeturePartPhoto from "./FeturePartPhoto";
const FeturedCard = ({
  headerTitle,
  title,
  feture,
  photoPart,
  photo,
  photoSvg,
}) => {
  return (
    <>
      {!photoPart && (
        <FeturePartFetured
          headerTitle={headerTitle}
          title={title}
          feture={feture}
          photoSvg={photoSvg}
        />
      )}

      {photoPart && <FeturePartPhoto photo={photo} />}
      
    </>
  );
};

export default FeturedCard;
