import React, { useEffect, useState } from "react";

const CharacrerAnimation = ({ charAnim, speed }) => {
  const [array, setArray] = useState([]);

  const charArray = charAnim.split("");

  useEffect(() => {
    let interval;

    const delays = setTimeout(() => {
      let i = 0;
      interval = setInterval(() => {
        setArray((n) => {
          if (n.length < charArray.length) {
            return [...n, charArray[i]];
          } else {
            clearInterval(interval);
            return n;
          }
        });
        i++;
      }, speed);
    }, 500);

    return () => {
      clearTimeout(delays);
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      {array.map((n, index) => {
        return (
          <span className="relative animate-moveText" key={index}>
            {n}
          </span>
        );
      })}
    </div>
  );
};

export default CharacrerAnimation;
