import React, { useRef, useState } from "react";
import Slider from "react-slick";
import slider from "react-slick/lib/slider";
import FeedBackCard from "../../../../Components/Cards/feedBackCard/FeedBackCard";
import FeturedCard from "../../../../Components/Cards/FeturedCard/FeturedCard";
import NumberCard from "../../../../Components/Cards/NumberCard/NumberCard";
import Numbers from "../../../../Data/home/FeedbackAndNumbers/Numbers.json";
import { useMobile } from "../../../../Hooks/useMobile";

import feddBackData from "../../../../Data/home/FeedbackAndNumbers/FeedBack";
import useOnScreen from "../../../../Hooks/useOnScreen";

const FeedbackAndNumbers = () => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  const [sliderIndex, setSliderIndex] = useState();
  const [preventHover, setpreventHover] = useState(true);
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    afterChange: (current) => setSliderIndex(current),
  };

  const settingsMobile = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setSliderIndex(current),
  };

  const dataSetings = () => {
    if (isMobile) {
      return settingsMobile;
    }
    if (isTablet) {
      return settingsMobile;
    }
    if (isDesktopLow) {
      return settings;
    }
  };


  const HandelsetpreventHover = (index,n) => {
    if(n){
      setpreventHover(true)
    }else{
      setpreventHover(false)
    }
  };

  return (
    <div className="max-w-[1280px] m-auto w-[90vw]">
      <div className="flex flex-wrap w-fit m-auto justify-center" ref={ref}>
        {
          Numbers.map((p) => (
            <NumberCard
              key={p.id}
              title={p.title}
              titleBreak={p.titleBreak}
              number={p.number}
              titleAndNumberColor={"#001659"}
            />
          ))}
      </div>
      <Slider {...dataSetings()} className="pb-5">
        {feddBackData.map((n, index) => (
          <FeedBackCard
            key={n.id}
            index={index}
            sliderIndex={preventHover ? sliderIndex : undefined}
            HeaderAdditional={n.subTitle}
            Title={n.title}
            feedback={n.feedBack}
            profilePhotoTest={n.Photo}
            HandelsetpreventHover={HandelsetpreventHover}
          />
        ))}
      </Slider>
    </div>
  );
};

export default FeedbackAndNumbers;
