import React, { useRef } from "react";
import NumberCard from "../../../../Components/Cards/NumberCard/NumberCard";

import Numbers from "../../../../Data/home/FeedbackAndNumbers/Numbers.json";
import { useMobile } from "../../../../Hooks/useMobile";
import useOnScreen from "../../../../Hooks/useOnScreen";

const NumbersSection = () => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();

  return (
    <div
      className={`
    ${isDesktopLow && "flex m-auto w-fit"}
    ${isTablet && "m-auto grid grid-cols-2 w-fit"}
    ${isMobile && "m-auto w-fit"}
    `}
      
    >
      {
        Numbers.map((n) => (
          <NumberCard
            title={n.title}
            number={n.number}
            key={n.id}
            titleAndNumberColor={"#00235a"}
            titleBreak={n.titleBreak}
          />
        ))}
    </div>
  );
};

export default NumbersSection;
