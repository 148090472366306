import React from "react";
import NavLinkNavBar from "./NavLinkNavBar";

const NavItemsDesktop = () => {
  return (
    <ul className={`flex w-full text-BlueTheme`}>
      <li className={`navbarDesktop  `}>
        <NavLinkNavBar
       
          className={``}
          to="/"
        >
          Home
        </NavLinkNavBar>
      </li>
      <li className={` navbarDesktop `}>
        <NavLinkNavBar to="/AboutUs">Rreth nesh</NavLinkNavBar>
      </li>
      <li className={` navbarDesktop `}>
        <NavLinkNavBar to="/OurServices">Shërbimet</NavLinkNavBar>
      </li>
      <li className={` navbarDesktop  `}>
        <NavLinkNavBar to="/OurProjectsHome">Projektet</NavLinkNavBar>
      </li>
      <li className={` navbarDesktop  `}>
        <NavLinkNavBar to="/contactUs">Kontakt</NavLinkNavBar>
      </li>
    </ul>
  );
};

export default NavItemsDesktop;
