import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMobile } from "../../../Hooks/useMobile";
import ButtonMiniPlus from "../../Buttons/ButtonMiniPlus";

const ServiceCard = ({ linkTo, headerTitle,title }) => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  const [hover, setHover] = useState(false);
  const titlecolor="grey"
  const titlecolorHover="white"

  const titlecolorHeaderHover="white"
  const titlecolorHeader="#001659"

  const buttonColor="white"
  const buttonColorHover="#ff5e14"
  return (
    <div
      to={linkTo}
      className={`${isDesktopLow&&""} ${isTablet&&""} ${isMobile&&""} h-full p-7 flex flex-col border-b-4 border-b-orange-500 border-2 transition-[2s] border-gray-200 ${
        !hover ? "" : "border-none bg-[#001659]"
      }`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span style={{ color:`${hover?titlecolorHover:titlecolor}` }} className="font-bold">{title}</span>
      <h1
        style={{ color:`${hover?titlecolorHeaderHover:titlecolorHeader}` }}
        className="text-2xl font-bold break-words  block"
      >
        {headerTitle}
      </h1>

      {/* <div className="mt-auto mr-auto">
        <ButtonMiniPlus
          backgroundColor={`${hover?buttonColorHover:buttonColor}`}
          onclickH2={""}
          onclickH2paramClick={""}
          textColor={`${hover?"white":"black"}`}
        >
          +
        </ButtonMiniPlus>
      </div> */}
    </div>
  );
};

export default ServiceCard;
