import React from "react";
import ClockSvg from "../../Assets/ContactIcons/Clock.svg"
const BuildWithFooter = () => {
  return (
    <div className="  pl-5 pr-5 text-gray-200">
      <div className="mb-4">
        <h1 className="text-3xl font-bold mb-2 text-white">Ndërtoni me Viante</h1>
        <p className="text-lg max-h-[155px] overflow-hidden">
        Viante Konstruksion është i përbërë nga një staf profesionistësh.
              Ekipi ynë do ju vijë në ndihmë në çdo moment për çdo pyetje. 
        </p>
      </div>
      <div className="flex">
        <img src={ClockSvg} alt="" 
        className="object-scale-down mb-auto mt-[4px]"/>
        <span className="ml-[10px]">
        8:00-17:00
          <br />
          Nga e hëna në të premte
        </span>
      </div>
    </div>
  );
};

export default BuildWithFooter;
