

import ourServices from "./Photos/ourServices.jpg"
const ServicesHeroData=[
    {
        id: 1,
        title: `Shërbimet Tona`,
        // subtitle: `EXPLORE THE FEATURES`,
        projection: "SHËRBIME",
        Content: `Bëjmë realitet ëndrrën dhe idenë tuaj përmes shërbimeve tona cilësore dhe profesioniste.  Vinante Konstruksion ka adaptuar që në fillim qasje dhe teknika në zhvillim që tani konsiderohen si standardi i industrisë.`,
        // Button1: { name: "button1", link: "/buton1" },
        // Button2: { name: "button2", link: "/buton2" },
        Photo: ourServices,
        LiteModeColors:false,
        darkBackground:true,
      },
]

export default ServicesHeroData