import React from "react";
import { NavLink } from "react-router-dom";
import { useMobile } from "../../../Hooks/useMobile";

const NavLinkNavBar = ({ classNameMobile, to, children }) => {
  const { isMobile } = useMobile();
  return (
    <NavLink
      style={({ isActive }) => ({
        color: isActive ? "#ff5e14" : "black",
      })}
      className={`pl-4 pr-4 flex align-middle w-full h-full 
    ${!isMobile && "border-[1px] border-r-[0px] border-gray-40/50"}
    ${isMobile&&"p-4"}
    `}
      to={to}
    >
      {
        <span
          className={`${isMobile && "mr-auto"}  ${!isMobile && "w-fit m-auto"}`}
        >
          {children}
        </span>
      }
      {isMobile && <span></span>}
    </NavLink>
  );
};

export default NavLinkNavBar;
