


import f1 from "./FeedBackPhotos/f1.jpg"
import f2 from "./FeedBackPhotos/f2.jpg"
import f3 from "./FeedBackPhotos/f3.jpg"
import f4 from "./FeedBackPhotos/f4.jpg"
const feddBackData = [
  {
    id: 1,
    subTitle: "DEVOLL HYDROPOWER",
    title: "J.V “LIMAK-AGE”",
    feedBack: `
    Vlerësimi ynë më i madh për të gjithë PROFESIONISTET E VIANTE për përpjekjet dhe energjinë tuaj që keni dhënë në  projektin DEVOLL HYDROPOWER.
    `,
    Photo: f1,
  },
  {
    id: 2,
    subTitle: "Qendra Rinore",
    title: "Bashkia Shkodër",
    feedBack: `Ka qenë një projekt shumë i kënaqshëm për rivitalizimin e Qendës Rinore dhe ne jemi shumë krenarë që shohim dizajnin me cilësi të lartë dhe rikonstruksionin e përfunduar.`,
    Photo: f2,
  },
  // {
  //   id: 3,
  //   subTitle: "subTitle2",
  //   title: "title2",
  //   feedBack: "feedBack2",
  //   Photo: f3,
  // },
  // {
  //   id: 4,
  //   subTitle: "subTitle3",
  //   title: "title3",
  //   feedBack: "feedBack3",
  //   Photo: f4,
  // },
];

export default feddBackData;
