import React, { useState } from "react";
const FeturePartFetured = ({headerTitle, title, feture,photoSvg}) => {
    const [hover, setHover] = useState(false);
  return (
    <div
      className={`
      h-[347px] sm:h-auto border-b-4 border-2 border-gray-200  border-b-orangeTheme
      `}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="h-[43%] w-full  flex">
        <img src={photoSvg}
          className={`bg-white h-24 w-24 mt-auto mb-auto m-auto ${
            hover ? "scale-[1.01] relative bottom-2 transition-[2s]" : ""
          }`}
        />
      </div>
      <div className=" h-[57%] w-full">
        <span className="m-auto block w-fit text-sm text-gray-700">
          {headerTitle}
        </span>
        <h1 className="m-auto block w-fit text-3xl font-bold text-BlueTheme">
          {title}
        </h1>
        <div className="seperator h-4"></div>
        <p className="break-words overflow-hidden text-center w-3/4 m-auto text-gray-700 text-base max-h-[100px]">
          {feture}
        </p>
      </div>
    </div>
  );
};

export default FeturePartFetured;
