import React from "react";
import Slider from "react-slick";
import { useMobile } from "../../../../../Hooks/useMobile";


function SampleNextArrow(props) {
    const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
    const { className, style, onClick } = props;
    return (
      <div
        className={`opacity-100 w-[50px] h-[50px] top-[45%] right-[0px] bg-orange-600 rounded-sm flex absolute text-white text-xl z-10
        before:!opacity-100 before:!m-auto before:!content-['→']`}
        style={{ ...style, display: "flex" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
    const { className, style, onClick } = props;
    return (
      <div
        className={`opacity-100 w-[50px] h-[50px] top-[45%] bg-blue-900 rounded-sm left-0 flex absolute text-white text-xl z-10
        before:!opacity-100 before:!m-auto before:!content-['←']
        `}
        style={{ ...style, display: "flex"  }}
        onClick={onClick}
      />
    );
  }

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  // slidesToScroll: 4,
  initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
};
const ProjectPageSlider = ({ photosArray }) => {

  return (
    <div className="sm:w-[70%] w-[100%] max-w-[1280px] min-h-[700px] max-h-[700px] m-auto overflow-hidden">
      <Slider {...settings} className="h-full  max-h-full">
        {photosArray.map((n) => (
          <img src={n.Photo} className=" max-h-[70vh] w-full object-contain " key={n.PhotoId}></img>
        ))}
      </Slider>
    </div>
  );
};

export default ProjectPageSlider;
