import React from "react";
import { Link } from "react-router-dom";

const NextAndPrevProjects = ({ ListPro }) => {
  return (
    <div className="max-w-[1280px] flex justify-between m-auto pl-10 pr-10">
      {ListPro.procectPageFilteredPrev ? (
        <div className="flex">
          <Link
            className="w-[50px] h-[50px] bg-darkBlueTheme flex justify-center items-center text-white font-bold rounded-md"
            to={`/OurProjectsPage/${ListPro.procectPageFilteredPrev}`}
          >
            <span className="font-extrabold">{"<"}</span>
          </Link>
          {/* <span className="flex justify-center items-center ml-5 font-bold text-darkBlueTheme">previous project</span> */}
        </div>
      ) : (
        <div className="ControlSpace h-1 w-1"></div>
      )}
      <Link
        className="grid grid-cols-2 h-[50px] gap-[2px] bg-darkBlueTheme w-[50px] p-4 rounded-md"
        to={`/`}
      >
        <div className="Decoration bg-white rounded-sm"></div>
        <div className="Decoration bg-white rounded-sm"></div>
        <div className="Decoration bg-white rounded-sm"></div>
        <div className="Decoration bg-white rounded-sm"></div>
      </Link>
      {ListPro.procectPageFilteredNext ? (
        <div className="flex">
            {/* <span className="flex justify-center items-center mr-5 font-bold text-darkBlueTheme">next project</span> */}
          <Link
            className="w-[50px] h-[50px] bg-darkBlueTheme flex justify-center items-center text-white font-bold rounded-md"
            to={`/OurProjectsPage/${ListPro.procectPageFilteredNext}`}
          >
            <span className="font-extrabold">{">"}</span>
          </Link>
          
        </div>
      ) : (
        <div className="ControlSpace h-1 w-1"></div>
      )}
    </div>
  );
};

export default NextAndPrevProjects;
