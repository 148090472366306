import React from "react";
import { useMobile } from "../../Hooks/useMobile";
import useTargetedMediaQuery from "../../Hooks/useTargetedMediaQuery";

const ContactPart = ({ svg, linkTo }) => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  return (
    <a
      href={linkTo}
      className={`h-full w-[80px] max-w-[80px] border-[1px] border-gray-400/50 flex m-auto hover:bg-orangeTheme
    ${!isTablet&&!isMobile ? "border-t-0":""}
    `}
    >
      <span className="mt-auto mb-auto m-auto w-5 h-5">
        <img className="w-full" src={svg} />
      </span>
    </a>
  );
};

const FooterCoppyRight = () => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  const [targetPro] = useTargetedMediaQuery(400);
  return (
    <>
      <div className="w-full p-0 m-0 left-0 absolute h-[1px] bg-gray-400/50"></div>
      <div
        className={`${isDesktopLow && "h-20 flex justify-between"}
    ${isMobile && "flex-col h-auto gap-5 w-fit m-auto"}
    ${isTablet && "flex-col h-auto gap-5 w-fit m-auto"}
    ${targetPro && "w-90%"}
    `}
      >
        <div
          className={`logo w-72 h-full flex bg-orangeTheme 
      ${isMobile && "h-[84px] m-auto"} 
      ${isTablet && "h-[84px] m-auto"} 
      ${isDesktopLow && "h-20"}`}
        >
          <span className="mt-auto mb-auto m-auto flex text-white text-2xl font-semibold">
            Viante
          </span>
        </div>
        <div
          className={`logo w-72 h-full flex
      ${isMobile && "m-auto"}
      ${isTablet && "m-auto"}
      ${isDesktopLow && ""}
      
      `}
        >
          <div className="mt-auto mb-auto m-auto flex text-gray-400 pt-2 pb-2">
            <span className={``}>&copy; Viante Konstruksion<br/> Powered by <a href="https://laconics.net/">LACONICS</a></span>
          </div>
        </div>
        <div className="widthcontroler  h-[80px] flex">
          {/* <ContactPart
            linkTo={"/fafdsadfs"}
            svg={
              "https://wilmer.qodeinteractive.com/wp-content/uploads/2018/12/footer-icon-img-02.png"
            }
          />
          <ContactPart
            linkTo={"/fafdsadfs"}
            svg={
              "https://wilmer.qodeinteractive.com/wp-content/uploads/2018/12/footer-icon-img-02.png"
            }
          />
          <ContactPart
            linkTo={"/fafdsadfs"}
            svg={
              "https://wilmer.qodeinteractive.com/wp-content/uploads/2018/12/footer-icon-img-02.png"
            }
          />
          <ContactPart
            linkTo={"/fafdsadfs"}
            svg={
              "https://wilmer.qodeinteractive.com/wp-content/uploads/2018/12/footer-icon-img-02.png"
            }
          /> */}
        </div>
      </div>
    </>
  );
};

export default FooterCoppyRight;
