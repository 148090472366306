import React, { useRef, useState } from "react";
import { useMobile } from "../../../../Hooks/useMobile";
import { emailService } from "./emailService";
import EmailStatusCard from "./EmailStatusCard";

const ContactForm = () => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  const EmailForm = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState({sent:false,hasSent:false})

  const sendPayload = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      name: e.target[0].value,
      email: e.target[1].value,
      msg: e.target[2].value,
    };

    const sendEmail = await emailService(payload);

    if (sendEmail) {
      setIsLoading(false);
      setEmailSent({sent:true,hasSent:true})
    } else {
      setIsLoading(false);
      setEmailSent({sent:false,hasSent:true})
    }
  };

  const clearPrompt=()=>{
    setEmailSent({sent:false,hasSent:false})
  }

  return (
    <div
      className={`relative`}
    >
      
      <EmailStatusCard loading={isLoading} emailSent={emailSent} clearPrompt={clearPrompt} />
      <form className="p-2 md:p-28 " ref={EmailForm} onSubmit={sendPayload}>
        <div className="name">
          <span className={`font-semibold  text-sm text-[#565969]`}>
            EMRI JUAJ
          </span>
          <br />
          <input required className="ContactInput" name="name" type="text" />
        </div>
        <br />
        <br />
        <div className="email">
          <span className={`font-semibold  text-sm text-[#565969]`}>
            EMAILI JUAJ
          </span>
          <br />
          <input required type="email" name="email" className="ContactInput" />
        </div>
        <br />
        <br />
        <div className="">
          <span className={`font-semibold  text-sm text-[#565969]`}>
            MESAZHI JUAJ
          </span>
          <textarea required className="ContactInput" name="msg" />
        </div>
        <br />
        <br />
        <button
          className="buttonSendDiv rounded-sm pr-[57px] pl-[57px] pt-[10px] pb-[10px] transition duration-150 ease-in-out  bg-orangeTheme text-white hover:shadow-xl hover:shadow-orangeTheme/50	"
          type="submit"
        >
          DËRGO
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
