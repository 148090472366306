import React from 'react'
import Banner from '../../../Components/PageHeaders/Banner/BannerHero/Banner'
import AboutUsData from '../../../Data/PageHeadersHeroData/AboutUs/AboutUsBanner'
const AboutUsHero = () => {
  return (
    <div>
        <Banner
        BannerData={AboutUsData}
        bannerMini={true}
        />
    </div>
  )
}

export default AboutUsHero