import React from "react";
import { Link } from "react-router-dom";

const OtherProjectsCard = ({photo,subTitle,title,Project_Page_link_id}) => {
  return (
    <Link to={`/OurProjectsPage/${Project_Page_link_id}`} className="h-[330px]">
      <img src={photo} className="h-4/6 bg-black mb-5"></img>
      <p className="text-[15px] font-semibold">{subTitle}</p>
      <h1 className="text-[20px] font-bold text-BlueTheme mb-2">
        {title}
      </h1>
    </Link>
  );
};

export default OtherProjectsCard;
