import { useEffect, useState } from "react";

const UseMediaQueryHook = (min,med,max) => {
  const [resolution, setResolution] = useState();

  const handleResize = () => {
    if (window.innerWidth > max) {
      setResolution(max);
    }
    if (window.innerWidth > med && window.innerWidth <= max) {
      setResolution(med);
    }
    if (window.innerWidth <= min) {
      setResolution(min);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return [resolution];
};

export default UseMediaQueryHook;
