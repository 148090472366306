import React, { useEffect, useRef, useState } from "react";
import { useMobile } from "../../../Hooks/useMobile";
import useOnScreen from "../../../Hooks/useOnScreen";

const NumberAnimation = ({
  title,
  titleBreak,
  number,
  titleAndNumberColor,
}) => {
  const [NumberP, setNumberP] = useState(0);
  useEffect(() => {
    let isMounted = true;
    if (NumberP < number) {
      setTimeout(() => {

        if (isMounted) {
          setNumberP((n) => n + 1);
        }
      }, number>100?10:50);
    }

    return () => {
      clearTimeout();
      isMounted = false;
    };
  }, [NumberP]);
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  return (
    <>
      <div className="w-72 h-56 ">
        <div className="h-0 w-0 relative">
          {number && <span style={{
              "WebkitTextFillColor": "transparent",
              "WebkitTextStrokeWidth": "1px",
              "WebkitTextStrokeColor": "#d2d2d4",
          }} className={`min-w-[186px] text-[#f5f6f7] h-[1em] inline-block align-middle font-[600] overflow-hidden text-[180px] top-[0px] left-[0px] leading-[1em] absolute`}>{NumberP}</span>}
        </div>

        <div className="h-0 w-0 relative top-[4.7rem] left-[7.95rem] font-bold">
          {title && (
            <span
              style={{ color: titleAndNumberColor }}
              className={` absolute`}
            >
              {title}
            </span>
          )}
        </div>
        <div className="h-0 w-0 relative top-[2.4rem] left-[4.95rem] text-[35px] font-medium">
          {titleBreak && (
            <span
              style={{ color: titleAndNumberColor }}
              className={` absolute`}
            >
              {titleBreak}
            </span>
          )}
        </div>

        <div className="h-0 w-0 relative top-[4rem] left-[3rem] text-[60px] font-bold">
          {number && (
            <span
              style={{ color: titleAndNumberColor }}
              className={` absolute`}
            >
              {NumberP}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

const NumberCard = ({ title, titleBreak, number, titleAndNumberColor }) => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  return (
    <div ref={ref} className={`w-72 h-56`}>
      {isVisible && (
        <NumberAnimation
          title={title}
          titleBreak={titleBreak}
          number={number}
          titleAndNumberColor={titleAndNumberColor}
        />
      )}
    </div>
  );
};

export default NumberCard;
