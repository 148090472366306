
import img1 from "../Projects/ProjectsPhotos/ProjectList/1.jpg"
import img2 from "../Projects/ProjectsPhotos/ProjectList/2.jpg"
import img3 from "../Projects/ProjectsPhotos/ProjectList/3.jpg"
import img4 from "../Projects/ProjectsPhotos/ProjectList/4.jpg"
import Hidrocentral1 from "../Projects/ProjectsPhotos/ProjectList/Hidrocentral1.jpg"
import GodinBanimiProjekti2 from "../Projects/ProjectsPhotos/ProjectList/Godin-Banimi-Projekti2.jpg"
import Rikonstruksion3 from "../Projects/ProjectsPhotos/ProjectList/Rikonstruksion3.jpg"
import Ujesjelles4 from "../Projects/ProjectsPhotos/ProjectList/Ujesjelles4.jpg"
import Selite5 from "../Projects/ProjectsPhotos/ProjectList/Selite5.jpg"
import Rruge6 from "../Projects/ProjectsPhotos/ProjectList/Rruge6.jpg"


const projectList=[
{
    id:1,
    photo:Hidrocentral1,
    subTitle:"Ndertim Hidrocentrali",
    title:"DEVOLL HYDROPOWER",
    projectDescription:"Ndërtim i hidrocentralit të Devollit (DEVOLL HYDROPOWER), projekti i SRATCRAFT, kantieri i Banjës dhe Moglicës.",
    Project_Page_link_id:1
},
{
    id:2,
    photo:GodinBanimiProjekti2,
    subTitle:"Ndertim Godina Banimi",
    title:"Godina Banimi",
    projectDescription:"Rehabilititmi i vedbanimeve te komunitetit romë në Shkozë (Ndërtim i godinave të banimit)",
    Project_Page_link_id:2
},
{
    id:3,
    photo:Rikonstruksion3,
    subTitle:"Rikonstruksion",
    title:"Rikonstruksion Qendra Rinore",
    projectDescription:`Rikonstruksion i Qendrës Rinore Publike, Bashkia Shkodër - kati 5 dhe 6 te Galeria e Arteve Shkoder "YES FUTURE"`,
    Project_Page_link_id:3
},
{
    id:4,
    photo:Ujesjelles4,
    subTitle:"Ndertim Ujësjellës",
    title:"Ujësjellës lagjia 55, 85, Gramsh",
    projectDescription:`Rikonstruksioni i rrjetit të shpërndarjes së ujësjellësit, Lagjia "85" dhe "Delinje", Gramsh.`,
    Project_Page_link_id:4
},
{
    id:5,
    photo:Selite5,
    subTitle:"Ndertim Ujësjellës",
    title:"Ujësjellës Selitë, Vasil Shanto, Tiranë",
    projectDescription:`Përmirësim furnizimi me ujë në bllokun e ‘‘Vilave Selitë‘‘ dhe ndërtim rrjet ujësjellësi në bllokun “Vasil Shanto”, Tiranë”`,
    Project_Page_link_id:5
},
{
    id:6,
    photo:Rruge6,
    subTitle:"Rikonstruksion Rruge",
    title:"Rrugë Lagjia Agjensia,Gramsh",
    projectDescription:`Rikonstruksioni i rrugës lagjia "Agjensia", Gramsh`,
    Project_Page_link_id:6
},
]

export default projectList