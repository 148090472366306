import React from "react";
import ContentCard from "../../../../Components/ContentCard/ContentCard";
import UseMediaQueryHook from "../../../../Hooks/mediaQuery";
import ProjectMgrPhoto from "../../../../Assets/OurServices/ProjectManagment/ProjectManagment.jpg";
import { useMobile } from "../../../../Hooks/useMobile";


const ProjectManagmentOurServices = () => {
  const [specialQuery] = UseMediaQueryHook(1000, 1200, 1280);
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  return (
    <div
      style={{ backgroundImage: `url(${ProjectMgrPhoto})` ,backgroundSize:"cover"}}
      className={`flex w-full h-full bg-repeat-x m-auto pl-10 pr-10 justify-center bg-center ${
        specialQuery == 1000 && "flex-col max-w-none"
      }
      ${isTablet&&"bg-no-repeat"}
      ${isMobile&&"!p-0"}
      `}
    >
      <div></div>
      <div className={`
      ${isDesktopLow&&"flex z-10 h-full ml-[65%] scale-125 mr-10"}
      ${isTablet&&"flex z-10 h-full w-4/5"}
      
      `}>
        <ContentCard
          colorTheme={"orange"}
          headerTopTitle={"Viante Konstruktion"}
          headerTitle={"Menaxhim Projektesh"}
          Content={`Procesi ynë i menaxhimit të projekteve siguron performancë të lartë,integritet, cilësi dhe siguri të jashtëzakonshme. Çdo aspekt i marrëdhënies sonë me ju monitorohet me kujdes, që nga takimi ynë i parë deri në ditën e fundit të një projekti dhe më gjerë. Ju mund të prisni mendim krijues, planifikim të detajuar dhe menaxhim të jashtëzakonshëm të projektit nga Viante Konstruksion.`}
          buttonTitle={"Projekte"}
          linkTo={"/OurProjectsHome"}
        />
      </div>
    </div>
  );
};

export default ProjectManagmentOurServices;
