import logo from "./logo.svg";
import "./App.css";
import NavBar from "./Components/PageHeaders/NavBar/NavBar";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home/Home";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Footer from "./Components/PageFooter/Footer";
import AboutUs from "./Pages/AboutUs/AboutUs";
import OurServices from "./Pages/OurServices/OurServices";
import ContactUs from "./Pages/ContactUs/ContactUs";
import OurProjectsHomePage from "./Pages/OurProjectsHomePage/OurProjectsHomePage";
import OurProjectsPage from "./Pages/OurProjectsPage/OurProjectsPage";
import { useEffect } from "react";
import "./Helpers/SliderStyles/SlickSliderChangeStyles.css"

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/OurServices" element={<OurServices />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/OurProjectsHome" element={<OurProjectsHomePage />} />
        <Route path="/OurProjectsPage/:Id" element={<OurProjectsPage />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
