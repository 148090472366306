import React, { useRef } from "react";
import ContentCard from "../../../../Components/ContentCard/ContentCard";
import ProjectManagment from "../../../../Assets/home/projectManagment/projectManagment.jpg";
import UseMediaQueryHook from "../../../../Hooks/mediaQuery";
import useTargetedMediaQuery from "../../../../Hooks/useTargetedMediaQuery";
import defaultPattern from "./default_pattern.png";
import { useMobile } from "../../../../Hooks/useMobile";
import PhotoAnimation from "../../../../Helpers/Animation/PhotoAnim/PhotoAnimation";
import useOnScreen from "../../../../Hooks/useOnScreen";

const ProjectManagmentSection = () => {
  const [specialQuery] = UseMediaQueryHook(1000, 1200, 1280);
  const [specialQuery1415] = UseMediaQueryHook(1415, 1200, 1280);
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  return (
    <div
      className={`flex max-w-[1500px] w-fit m-auto pl-10 pr-10 justify-center ${
        specialQuery == 1000 && "flex-col max-w-none"
      }
      ${isDesktopLow && specialQuery1415 != 1415 && "scale-[1.26] pt-10 pb-10"}
      ${isDesktopLow && specialQuery1415 == 1415 && "scale-[1.16] pt-10 pb-10"}
      `}
    >
      <div
        ref={ref}
        className={`  ${specialQuery == 1000 && " m-auto object-scale-down"}
        ${isDesktopLow && "scale-[0.85] w-[600px] h-[394px]"}
        `}
      >
        {isVisible && (
          <PhotoAnimation width={"100%"} height={"100%"}>
            <img
              src={ProjectManagment}
              className={`m-auto object-scale-down
        `}
            />
          </PhotoAnimation>
        )}
      </div>
      <div className={`decoration relative h-0 w-0 bottom-10 right-96 `}>
        <div
          style={{ backgroundImage: `url(${defaultPattern})` }}
          className={`h-[500px] w-[900px] -z-10 absolute`}
        ></div>
      </div>
      <div className=" flex scale-105">
        <ContentCard
          // colorTheme={"orange"}
          headerTopTitle={"Viante Konstruktion"}
          headerTitle={"Menaxhim Projektesh"}
          Content={`Procesi ynë i menaxhimit të projekteve siguron performancë të lartë,integritet, 
          cilësi dhe siguri të jashtëzakonshme. Çdo aspekt i marrëdhënies sonë me 
          ju monitorohet me kujdes, që nga takimi ynë i parë deri në ditën e fundit të një projekti 
          dhe më gjerë. Ju mund të prisni mendim krijues, 
          planifikim të detajuar dhe menaxhim të jashtëzakonshëm të projektit 
          nga Viante Konstruksion.`}
          buttonTitle={"Projekte"}
          linkTo={"/OurProjectsHome"}
        />
      </div>
    </div>
  );
};

export default ProjectManagmentSection;
