import { useMobile } from "../../../Hooks/useMobile";
import BannerTitlePart from "../../BannerTitlePart/BannerTitlePart";
import ServiceCard from "../../Cards/serviceCard/ServiceCard";
import servicedata from "../../../Data/ServiceData/ServiceData.json"
import ProjectManagmentSection from "../../../Pages/Home/sections/projectmanagment/ProjectManagmentSection";
const ServiceSectionHome = ({bgOrange}) => {

  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  return (
    <div className="w-full max-w-[1500px] m-auto">
      <div className="w-2 h-2"></div>
      <div className="m-10 overflow-hidden z-10">
        <div className={`${isDesktopLow&&"pl-20 pt-10"}
        ${isTablet&&"pl-10"}
        ${isMobile&&"pl-0 relative left-[-40px]"}
        `}>
          <BannerTitlePart
            title={"SHËRBIME"}
            projection={"SHËRBIME"}

          />
        </div>
        <div
          className={` flex flex-col gap-10 h-full min-h-[250px] w-auto ${
            isTablet && "grid grid-cols-2 m-auto pl-0 pr-0"
          } ${isDesktopLow && "grid grid-cols-4"}`}
        >
          {servicedata.map((n) => (
            <ServiceCard
              key={n.id}
              headerTitle={n.headerTitle}
              title={n.title}
            />
          ))}
        </div>
      </div>
      <br />
      <br />
      <br />
      {/* <div className="seperate h-[100px] w-full"></div> */}
    </div>
  );
};

export default ServiceSectionHome;
