import React from "react";
import { useMobile } from "../../../../Hooks/useMobile";
import BannerTitlePart from "../../../BannerTitlePart/BannerTitlePart";
import ButtonN2Link from "../../../Buttons/ButtonN2Link";



const HeroBannerPart = ({
  projection,
  subtitle,
  title,
  Content,
  Button1,
  Button2,
  LiteMode,
}) => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();

  return (
    <div
      className={`w-auto md:max-w-xl sm:max-w-lg
    ${isMobile && "-ml-10"}
    `}
    >
      <BannerTitlePart
        title={title}
        projection={projection}
        subtitle={subtitle}
        subtitleColor={LiteMode ? "#565969" : "white"}
        description={Content}
        titleColor={LiteMode ? "#00235a" : "white"}
        descriptionColor={LiteMode ? "#565969" : "white"}
      />
      <div className="grid w-fit gap-2 md:flex sm:flex ml-10">
        {Button1 && (
          <ButtonN2Link
            name={Button1.name}
            linkTo={Button1.link}
            backgroundColor="#ff5e14"
          >
            {Button1.name}
          </ButtonN2Link>
        )}

        {Button2 && (
          <ButtonN2Link
            name={Button2.name}
            linkTo={Button2.link}
            backgroundColor="#001659"
          >
            {Button2.name}
          </ButtonN2Link>
        )}
      </div>
    </div>
  );
};

export default HeroBannerPart;
