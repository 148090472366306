import React from "react";

const ButtonMiniPlus = ({
  children,
  backgroundColor,
  onclickH2,
  onclickH2paramClick,
  textColor,
}) => {
  return (
    <button
      onClick={() => onclickH2(onclickH2paramClick)}
      style={{ backgroundColor: backgroundColor, color: textColor }}
      className={`w-10 h-10 pt-0 pb-0 rounded-sm font-bold text-xl flex justify-center items-center`}
    >
      <span className="h-fit w-fit font-bold">{children}</span>
    </button>
  );
};

export default ButtonMiniPlus;
