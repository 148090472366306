
import email from "../../Assets/ContactIcons/email.png"
import location from "../../Assets/ContactIcons/location.png"
import phone from "../../Assets/ContactIcons/phone.png"

const contactData = {
  location: {
    locationIcon: location,
    location: `Rr.: "F. Plaku", Pallati. 21 Ap. 27/B Tiranë`,
    linkToInfo:`https://www.google.com/maps/search/Rr.:+%22F.+Plaku%22,+Pall.+21+Ap.+27%2FB+Tiran%C3%AB/@41.3184403,19.8016604,16z/data=!3m1!4b1`
  },
  PhoneNumber: {
    PhoneIcon: phone,
    Pone1: "+355 69 20 65 949",
    // Phone2: "+ (123) 1234-567-8902",
  },
  EmailAddress: {
    EmailAddressIcon: email,
    Email1: "info@viante.al",
    // Email2: "wilmer2@qodeinteractive.com",
  },
};




export default contactData