import React from "react";
import BannerTitlePart from "../../../../Components/BannerTitlePart/BannerTitlePart";
import FeturedCard from "../../../../Components/Cards/FeturedCard/FeturedCard";
import FeturedData from "../../../../Data/home/fetured/FeturdData";
import { useMobile } from "../../../../Hooks/useMobile";

const FetureSection = () => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();

  return (
    <div className="pl-7 pr-7 m-auto max-w-[1280px]">
      <div className="">
        <BannerTitlePart
          title={"PROFILI"}
          projection={"PROFILI"}
          titleColor={"#001659"}

        />
      </div>
      <div
        className={`grid gap-2 w-auto m-auto ${
          isTablet && "grid-cols-2 gap-10 m-auto pl-0 pr-0 max-w-[940px]"
        } ${isDesktopLow && "grid-cols-3 gap-7"} ${isMobile && "grid-cols-1"}`}
      >
        {FeturedData.map((p) =>
          p.photoPart ? (
            <FeturedCard key={p.id} photo={p.photo} photoPart={p.photoPart} />
          ) : (
            <FeturedCard
              key={p.id}
              headerTitle={p.headerTitle}
              title={p.title}
              feture={p.feture}
              photoSvg={p.photoSvg}
              photoPart={p.photoPart}
            />
          )
        )}
      </div>
    </div>
  );
};

export default FetureSection;
