import React from "react";


import LocationMap from "../../Assets/Footer/albaniaMap.png"

const OurLocations = () => {
  return (
    <div className="  pl-5 pr-5 text-gray-200 h-[170px] mb-10">
      <div className="">
        <h1 className="text-3xl font-bold mb-2 text-white">Zyra in Tirana</h1>
      </div>
      <img src={LocationMap} className=" h-full object-cover"></img>
    </div>
  );
};

export default OurLocations;
