import P2F1 from "./ProjectPagePhotoFolder/P2F1.jpg";
import P2F2 from "./ProjectPagePhotoFolder/P2F2.jpg";


// Hidrocentral1
import Hidrocentral1 from "./ProjectsPhotos/ProjectPage/Hidrocentral1/Hidrocentral1.jpg"
import moglica_19 from "./ProjectsPhotos/ProjectPage/Hidrocentral1/moglica_19.jpg"
import moglica_22 from "./ProjectsPhotos/ProjectPage/Hidrocentral1/moglica_22.jpg"
import moglica_23 from "./ProjectsPhotos/ProjectPage/Hidrocentral1/moglica_23.jpg"
import moglica_24 from "./ProjectsPhotos/ProjectPage/Hidrocentral1/moglica_24.jpg"
import moglica_25 from "./ProjectsPhotos/ProjectPage/Hidrocentral1/moglica_25.jpg"
import moglica_27 from "./ProjectsPhotos/ProjectPage/Hidrocentral1/moglica_27.jpg"
import moglica_28 from "./ProjectsPhotos/ProjectPage/Hidrocentral1/moglica_28.jpg"
import moglica_29 from "./ProjectsPhotos/ProjectPage/Hidrocentral1/moglica_29.jpg"
import moglica_30 from "./ProjectsPhotos/ProjectPage/Hidrocentral1/moglica_30.jpg"
import moglica_31 from "./ProjectsPhotos/ProjectPage/Hidrocentral1/moglica_31.jpg"


// Godin-Banimi-Projekti2
import shkoze_2 from "./ProjectsPhotos/ProjectPage/Godin-Banimi-Projekti2/shkoze_2.jpg"
import shkoze_8 from "./ProjectsPhotos/ProjectPage/Godin-Banimi-Projekti2/shkoze_8.jpg"
import shkoze_10 from "./ProjectsPhotos/ProjectPage/Godin-Banimi-Projekti2/shkoze_10.jpg"
import shkoze_19 from "./ProjectsPhotos/ProjectPage/Godin-Banimi-Projekti2/shkoze_19.jpg"
import shkoze_21 from "./ProjectsPhotos/ProjectPage/Godin-Banimi-Projekti2/shkoze_21.jpg"
import shkoze_25 from "./ProjectsPhotos/ProjectPage/Godin-Banimi-Projekti2/shkoze_25.jpg"
import shkoze_26 from "./ProjectsPhotos/ProjectPage/Godin-Banimi-Projekti2/shkoze_26.jpg"
import shkoze_35 from "./ProjectsPhotos/ProjectPage/Godin-Banimi-Projekti2/shkoze_35.jpg"
import shkoze_38 from "./ProjectsPhotos/ProjectPage/Godin-Banimi-Projekti2/shkoze_38.jpg"

// Rikonstruksion3
import future_5 from "./ProjectsPhotos/ProjectPage/Rikonstruksion3/future_5.jpg"
import future_6 from "./ProjectsPhotos/ProjectPage/Rikonstruksion3/future_6.jpg"
import future_8 from "./ProjectsPhotos/ProjectPage/Rikonstruksion3/future_8.jpg"
import future_10 from "./ProjectsPhotos/ProjectPage/Rikonstruksion3/future_10.jpg"
import future_13 from "./ProjectsPhotos/ProjectPage/Rikonstruksion3/future_13.jpg"
import future_14 from "./ProjectsPhotos/ProjectPage/Rikonstruksion3/future_14.jpg"
import future_15 from "./ProjectsPhotos/ProjectPage/Rikonstruksion3/future_15.jpg"

// Ujesjelles4
import gramshw_2 from "./ProjectsPhotos/ProjectPage/Ujesjelles4/gramshw_2.jpg"
import gramshw_4 from "./ProjectsPhotos/ProjectPage/Ujesjelles4/gramshw_4.jpg"
import gramshw_5 from "./ProjectsPhotos/ProjectPage/Ujesjelles4/gramshw_5.jpg"
import gramshw_8 from "./ProjectsPhotos/ProjectPage/Ujesjelles4/gramshw_8.jpg"
import gramshw_10 from "./ProjectsPhotos/ProjectPage/Ujesjelles4/gramshw_10.jpg"
import gramshw_16 from "./ProjectsPhotos/ProjectPage/Ujesjelles4/gramshw_16.jpg"
import gramshw_17 from "./ProjectsPhotos/ProjectPage/Ujesjelles4/gramshw_17.jpg"
import gramshw_20 from "./ProjectsPhotos/ProjectPage/Ujesjelles4/gramshw_20.jpg"
import gramshw_23 from "./ProjectsPhotos/ProjectPage/Ujesjelles4/gramshw_23.jpg"


// Selite5
import vshanto_1 from "./ProjectsPhotos/ProjectPage/Selite5/vshanto_1.jpg"
import vshanto_3 from "./ProjectsPhotos/ProjectPage/Selite5/vshanto_3.jpg"
import vshanto_5 from "./ProjectsPhotos/ProjectPage/Selite5/vshanto_5.jpg"
import vshanto_6 from "./ProjectsPhotos/ProjectPage/Selite5/vshanto_6.jpg"
import vshanto_8 from "./ProjectsPhotos/ProjectPage/Selite5/vshanto_8.jpg"
import vshanto_9 from "./ProjectsPhotos/ProjectPage/Selite5/vshanto_9.jpg"
import vshanto_12 from "./ProjectsPhotos/ProjectPage/Selite5/vshanto_12.jpg"
import vshanto_13 from "./ProjectsPhotos/ProjectPage/Selite5/vshanto_13.jpg"
import vshanto_15 from "./ProjectsPhotos/ProjectPage/Selite5/vshanto_15.jpg"


// Rruge6
import gramsh2_1 from "./ProjectsPhotos/ProjectPage/Rruge6/gramsh2_1.jpg"
import gramsh2_2 from "./ProjectsPhotos/ProjectPage/Rruge6/gramsh2_2.jpg"
import gramsh2_13 from "./ProjectsPhotos/ProjectPage/Rruge6/gramsh2_13.jpg"
import gramsh2_15 from "./ProjectsPhotos/ProjectPage/Rruge6/gramsh2_15.jpg"
import gramsh2_17 from "./ProjectsPhotos/ProjectPage/Rruge6/gramsh2_17.jpg"
import gramsh2_22 from "./ProjectsPhotos/ProjectPage/Rruge6/gramsh2_22.jpg"
import gramsh2_25 from "./ProjectsPhotos/ProjectPage/Rruge6/gramsh2_25.jpg"
import gramsh2_27 from "./ProjectsPhotos/ProjectPage/Rruge6/gramsh2_27.jpg"

const ProjectPageData = [
  {
    id: 1,
    // Title: "pro1",
    // Project: `
    // pro1 pro1 pro1 pro1 pro1 pro1 pro1pro1pro1pro1pro1pro1pro1
    // pro1 pro1 pro1 pro1 pro1 pro1 pro1pro1pro1pro1pro1pro1pro1
    // pro1 pro1 pro1 pro1 pro1 pro1 pro1pro1pro1pro1pro1pro1pro1
    // pro1 pro1 pro1 pro1 pro1 pro1 pro1pro1pro1pro1pro1pro1pro1
    // `,
    // subtitle: "subtitle1",
    ProjectPageSection: {
      title: "Ndërtim Hidrocentrali Moglicë",
      ProjectDescription: `
      Realizimi i punimeve te kantiereve dhe faciliteteve (punimet e betonit
        dhe te çelikut për fabrikën e betonit, godina e energjisë, vepra e
        marrjes, kaperderdhesit, portës, galerisë, impianteve te thyerjes,
        podrumet dhe nivelimet per sit-in, betonin e skarpateve te shkallezuara
        (bermat) për mbrojtjen e skarpateve te godines se energjise, gropa
        septike e ujërave e zeza etj). Gjithashtu progresi kryesor i punës është
        Galeria e injektimit te llaçit (beton, punimet përfundimtare dhe
        çeliku).
      `,
    },

    ProjectInfo: {
      category: "Ndërtim",
      Date: "2015-2020",
      Taggs: "J.V “LIMAK-AGE”",
    },
    Photos: [
      {
        PhotoId: 11,
        Photo: Hidrocentral1,
      },
      {
        PhotoId: 3,
        Photo: moglica_23,
      },
      {
        PhotoId: 4,
        Photo: moglica_24,
      },
      {
        PhotoId: 5,
        Photo: moglica_25,
      },
      {
        PhotoId: 6,
        Photo: moglica_27,
      },
      {
        PhotoId: 7,
        Photo: moglica_28,
      },
      {
        PhotoId: 8,
        Photo: moglica_29,
      },
      {
        PhotoId: 9,
        Photo: moglica_30,
      },
      {
        PhotoId: 10,
        Photo: moglica_31,
      },
      {
        PhotoId: 1,
        Photo: moglica_19,
      },
      {
        PhotoId: 2,
        Photo: moglica_22,
      },
      
    ],
  },
  /////////
  /////////
  /////////
  /////////
  /////////
  {
    id: 2,
    // Title: "pro2",
    // Project: `
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // `,
    // subtitle: "subtitle2",
    ProjectPageSection: {
      title: "Godina Banimi",
      ProjectDescription: "Realizimi i gjithë punimeve të hekurit dhe betonit për strukturën e godinave të reja, punimet e furnizimit me energji elektrike, furnizimit me ujë dhe shkarkimeve. Gjithashtu gjithë punimet e finiturës, të dyer, dritare, të çatisë së bashku me punime te infrastrukturës së jashtme të objekteve së bashku me ndërtimin e trotuareve dhe rrjetit të jashtëm te F.U dhe shkarkimeve.",
    },
    ProjectInfo: {
      category: "Ndertim",
      Date: "",
      Taggs: "Bashkia E Tiranes",
    },
    Photos: [
      {
        PhotoId: 1,
        Photo: shkoze_2,
      },
      {
        PhotoId: 2,
        Photo: shkoze_8,
      },
      {
        PhotoId: 3,
        Photo: shkoze_10,
      },
      {
        PhotoId: 4,
        Photo: shkoze_19,
      },
      {
        PhotoId: 5,
        Photo: shkoze_21,
      },
      {
        PhotoId: 6,
        Photo: shkoze_25,
      },
      {
        PhotoId: 7,
        Photo: shkoze_26,
      },
      {
        PhotoId: 8,
        Photo: shkoze_35,
      },
      {
        PhotoId: 9,
        Photo: shkoze_38,
      },
    ],
  },
    /////////
  /////////
  /////////
  /////////
  /////////
  {
    id: 3,
    // Title: "pro2",
    // Project: `
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // `,
    // subtitle: "subtitle2",
    ProjectPageSection: {
      title: "Rikonstruksion Qendra Rinore",
      ProjectDescription: "Realizimin e gjithë punimeve të rikonstruksionit të objektit, që përfshijnë prej punimeve të prishjeve të shtresave, muratures, hidroizolimit të vjetër e deri ne punimet e mureve e reja ndarëse, suvatime, lyerjet shtresat e reja deri te pllakat dhe parketi, e me pas punimet per dyert, dritaret e reja. Punimet perfshijnë dhe punimet e rikonstruktimit të taracës me gjithë shtresëzimet e nevojshme së bashku me instalimet totale elektrike, kondicioimit, furnizimit me ujë dhe shkarkimeve si dhe sistemi i CCTV montorimit me kamera. Gjithashtu realizimi i ashesorit në përshtatje me kafazin e shkalleve dhe kërkesave të projektit.",
    },
    ProjectInfo: {
      category: "Rikonstruksion",
      Date: "",
      Taggs: "Bashkia Shkodër",
    },
    Photos: [
      {
        PhotoId: 1,
        Photo: future_5,
      },
      {
        PhotoId: 2,
        Photo: future_6,
      },
      {
        PhotoId: 3,
        Photo: future_8,
      },
      {
        PhotoId: 4,
        Photo: future_10,
      },
      {
        PhotoId: 5,
        Photo: future_13,
      },
      {
        PhotoId: 6,
        Photo: future_14,
      },
      {
        PhotoId: 7,
        Photo: future_15,
      },
    ],
  },
      /////////
  /////////
  /////////
  /////////
  /////////
  {
    id: 4,
    // Title: "pro2",
    // Project: `
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // `,
    // subtitle: "subtitle2",
    ProjectPageSection: {
      title: "Ujësjellës lagjia 55, 85, Gramsh",
      ProjectDescription: "Realizimin e gjithë punimeve për sistem të ri ujesjellësi ne lagjien 85 dhe Delinje - Gramsh, punime që përfshijnë prej gërmimeve dhe shtresave të nevojshme e me pas vendosjen e tubave, e deri ne punimet e betonit e të hekurit, të pusetave dhe rakorverive përkatese.",
    },
    ProjectInfo: {
      category: "Ndertim Ujësjellës",
      Date: "",
      Taggs: `Ujësjellës-Kanalizime Gramsh`,
    },
    Photos: [
      {
        PhotoId: 1,
        Photo: gramshw_2,
      },
      {
        PhotoId: 2,
        Photo: gramshw_4,
      },
      {
        PhotoId: 3,
        Photo: gramshw_5,
      },
      {
        PhotoId: 4,
        Photo: gramshw_8,
      },
      {
        PhotoId: 5,
        Photo: gramshw_10,
      },
      {
        PhotoId: 6,
        Photo: gramshw_16,
      },
      {
        PhotoId: 7,
        Photo: gramshw_17,
      },
      {
        PhotoId: 8,
        Photo: gramshw_20,
      },
      {
        PhotoId: 9,
        Photo: gramshw_23,
      },
    ],
  },
        /////////
  /////////
  /////////
  /////////
  /////////
  {
    id: 5,
    // Title: "pro2",
    // Project: `
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // `,
    // subtitle: "subtitle2",
    ProjectPageSection: {
      title: "Ujësjellës Selitë, Vasil Shanto, Tiranë",
      ProjectDescription: "Realizimin e gjithë punimeve për sistem të ri ujësjellësi në bllokun e Vilave Selitë si dhe në bllokun e banimit të Vasil Shanto.Punime që përfshijnë prej gërmimeve dhe shtresave të nevojshme e më pas vendosjen e tubave, e deri në punimet e betonit e të hekurit, të pusetave dhe rakorverive përkatese, si dhe kthimi në gjëndje fillestare asfaltim.",
    },
    ProjectInfo: {
      category: "Ndertim Ujësjellës",
      Date: "",
      Taggs: `Ujësjellës-Kanalizime Tirana`,
    },
    Photos: [
      {
        PhotoId: 1,
        Photo: vshanto_1,
      },
      {
        PhotoId: 2,
        Photo: vshanto_3,
      },
      {
        PhotoId: 3,
        Photo: vshanto_5,
      },
      {
        PhotoId: 4,
        Photo: vshanto_6,
      },
      {
        PhotoId: 5,
        Photo: vshanto_8,
      },
      {
        PhotoId: 6,
        Photo: vshanto_9,
      },
      {
        PhotoId: 7,
        Photo: vshanto_12,
      },
      {
        PhotoId: 8,
        Photo: vshanto_13,
      },
      {
        PhotoId: 9,
        Photo: vshanto_15,
      },
    ],
  },
          /////////
  /////////
  /////////
  /////////
  /////////
  {
    id: 6,
    // Title: "pro2",
    // Project: `
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // pro2 pro2 pro2 pro2 pro2 pro2 pro2pro2pro2pro2pro2pro2pro2
    // `,
    // subtitle: "subtitle2",
    ProjectPageSection: {
      title: `Rikonstruksioni i rrugës lagjia "Agjensia", Gramsh`,
      ProjectDescription: `Realizimi i të gjitha punimeve për rehabilitimin e rrugëve në lagjen Agjensia-Gramsh. Punime të cilat përfshijnë që prej atyre të gërmimit, të shtresave rrugore, të betonit dhe çelikut, tombinot rrethore, mure mbajtëse, mure gravitetacional, kanale betoni për mbrojtjen e rrugëve dhe skarpateve, trajtimit special të siperfaqes së rruges (shtreses së betonit)`,
    },
    ProjectInfo: {
      category: "Rikonstruksion",
      Date: "",
      Taggs: `Bashkia Gramsh`,
    },
    Photos: [
      {
        PhotoId: 1,
        Photo: gramsh2_1,
      },
      {
        PhotoId: 2,
        Photo: gramsh2_2,
      },
      {
        PhotoId: 3,
        Photo: gramsh2_13,
      },
      {
        PhotoId: 4,
        Photo: gramsh2_15,
      },
      {
        PhotoId: 5,
        Photo: gramsh2_17,
      },
      {
        PhotoId: 6,
        Photo: gramsh2_22,
      },
      {
        PhotoId: 7,
        Photo: gramsh2_25,
      },
      {
        PhotoId: 8,
        Photo: gramsh2_27,
      },
    ],
  },
];

export default ProjectPageData;
