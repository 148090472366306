import React, { useEffect, useState } from "react";

const PhotoAnimation = ({ height, width, children }) => {
  const [animDelay, setAnimDelay] = useState(false);
  useEffect(() => {
    const delay = setTimeout(() => {
      setAnimDelay(true)
    }, 500);
    return () => {
      clearTimeout(delay);
    };
  }, []);
  return (
    <>
      {animDelay && (
        <div style={{ width: width, height: height, overflow: "hidden" }}>
          <div style={{ width: width, height: height }}>
            <div className="animate-AnimPoto relative z-[4] h-full w-full inline"></div>
          </div>
          <div
            style={{
              width: width,
              height: height,
              position: "relative",
              top: "-100%",
            }}
          >
            <div
              style={{ animationDelay: "0.5s" }}
              className="animate-AnimPotoOther z-[10] h-full w-full  relative inline"
            ></div>
          </div>
          <div
            className="animate-hideImageAnim"
            style={{
              position: "relative",
              top: "-200%",
              width: "100%",
              height: "100%",
            }}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default PhotoAnimation;
