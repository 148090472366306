import React from "react";
import { Link, useParams } from "react-router-dom";
import ProjectPageData from "../../Data/Projects/ProjectPageData";
import OtherProjects from "./Sections/OtherProjects/OtherProjects";
import PhotoSliderAndContent from "./Sections/PhotoSliderAndContent/PhotoSliderAndContent";
import ProjectsData from "../../Data/Projects/ProjectList";
import NextAndPrevProjects from "./Sections/NextAndPrevProjects/NextAndPrevProjects";
import OtherProjectsHero from "./Sections/Hero/OtherProjectsHero";
const OurProjectsPage = () => {
  const params = useParams();

  const getProjectPageData = (PageId) => {
    const procectPageFiltered = ProjectPageData.filter((n) => n.id == PageId);
    return procectPageFiltered[0];
  };

  const ProjectsDataGetNextAndPerv = (PageId) => {
    const procectPageFilteredNext = ProjectsData.filter(
      (n) => n.id == parseInt(PageId) + 1
    );
    const procectPageFilteredPrev = ProjectsData.filter(
      (n) => n.id == parseInt(PageId) - 1
    );
    return {
      procectPageFilteredNext: procectPageFilteredNext[0]?.Project_Page_link_id,
      procectPageFilteredPrev: procectPageFilteredPrev[0]?.Project_Page_link_id,
    };
  };
  const filterdProjectPageGetPage = getProjectPageData(params.Id);
  return (
    <>
      {/* <div>
        <OtherProjectsHero
          subtitle={filterdProjectPageGetPage.subtitle}
          title={filterdProjectPageGetPage.Title}
          description={filterdProjectPageGetPage.Project}
        />
      </div> */}
      <div>
        <PhotoSliderAndContent pageData={filterdProjectPageGetPage} />
      </div>
      <div className="nextAndPrevProjects">
        <NextAndPrevProjects ListPro={ProjectsDataGetNextAndPerv(params.Id)} />
      </div>
      <div>
        <OtherProjects ProjectList={ProjectsData} />
      </div>
    </>
  );
};

export default OurProjectsPage;
