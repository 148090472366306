
import Brushsvg1 from "../../../Assets/home/fetured/svg/paintBrushIcon.png";
import Toolsvg2 from "../../../Assets/home/fetured/svg/toolSvg.png";
import ToolBoxsvg3 from "../../../Assets/home/fetured/svg/toolBoxSvg.png";



import BluePrintPhoto1 from "../../../Assets/home/fetured/photo/bluePrint.jpg"
import PaintGuyPhoto2 from "../../../Assets/home/fetured/photo/paintGuyIcon.jpg";
import WhitePaperPhoto3 from "../../../Assets/home/fetured/photo/whitePaper.jpg";



const FeturedData = [
    {
        id:1,
        photo:BluePrintPhoto1,
        photoPart:true
    },
    {
        id:2,
        // headerTitle:"Ndërtim",
        title:"Ndërtim",
        feture:`Viante është një kompani ndërtimi me nje staf ekspertesh.`,
        photoSvg:Brushsvg1,
        photoPart:false
    },
    {
        id:3,
        photo:PaintGuyPhoto2,
        photoPart:true
    },
    {
        id:4,
        // headerTitle:"test",
        title:"Konsulencë",
        feture:"Konsulencë në çdo fushë të ndërtimit për të arritur qëllimin tuaj.",
        photoSvg:Toolsvg2,
        photoPart:false
    },
    {
        id:5,
        photo:WhitePaperPhoto3,
        photoPart:true
    },
    {
        id:6,
        // headerTitle:"test",
        title:"Projektim",
        feture:"Ekspertët e Viante janë të gatshëm dhe të aftë të bëjnë zgjedhjen më të mirë për suksesin tuaj, duke bashkuar forcat dhe profesionalizmin.",
        photoSvg:ToolBoxsvg3,
        photoPart:false
    },
];

export default FeturedData;
