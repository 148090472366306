import React from "react";
import Banner from "../../../../Components/PageHeaders/Banner/BannerHero/Banner";
import OurProjectsHomeBanner from "../../../../Data/PageHeadersHeroData/OurProjectsHome/OurProjectsHomeBanner";
const Hero = () => {
  return (
    <div>
      <Banner BannerData={OurProjectsHomeBanner} />
    </div>
  );
};

export default Hero;
