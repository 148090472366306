

import AboutUs from "./Photos/aboutUs.jpg"
const AboutUsData=[
    {
        id: 1,
        title: `Rreth Nesh`,
        // subtitle: "stbtitle2",
        projection: "Viante",
        Content: `Viante Konstruksion shpk themeluar më datë 28 janar 2005 me NIPT
        K47103804L dhe me seli në Gramsh, Elbasan e në Tiranë është një nga
        Shoqëritë e ndertimit më të rëndësishme në Shqipëri, ku pika më e fortë e
        saj është cilësia ë bashkëkohore evropiane e produkteve me të cilat ajo
        vepron.`,
        // Button1: { name: "button1", link: "/buton1" },
        // Button2: { name: "button2", link: "/buton2" },
        Photo: AboutUs,
        LiteModeColors:false,
        darkBackground:true,
      },
]

export default AboutUsData