import React from 'react'
import Banner from '../../../../Components/PageHeaders/Banner/BannerHero/Banner'
import HomeBannerData from '../../../../Data/PageHeadersHeroData/Home/HomeBanner' 
const HomeHero = () => {
  return (
    <div>
        <Banner
        BannerData={HomeBannerData}
        />
    </div>
  )
}

export default HomeHero