import React from "react";
import Slider from "react-slick";
import BannerTitlePart from "../../../../Components/BannerTitlePart/BannerTitlePart";
import OurProjectsCard from "../../../../Components/Cards/ourProjectsCard/OurProjectsCard";
// import HeroBannerPart from "../../../../Components/HeroBanerPart/HeroBannerPart";
import OurProjectsSlider from "../../../../Components/Sliders/OurProjects/OurProjectsSlider";
import { useMobile } from "../../../../Hooks/useMobile";




const ProjectsSectionHome = () => {


  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();

  return (
    <div className="bg-[#00235a] pt-4 pb-4">
      <OurProjectsSlider />
    </div>
  );
};

export default ProjectsSectionHome;
