import React from "react";
import useTargetedMediaQuery from "../../Hooks/useTargetedMediaQuery";
import AboutUsHero from "./Sections/AboutUsHero";
import BuildingAboutUs from "./Sections/BuildingAboutUs";
import EfectiveTeamWork from "./Sections/EfectiveTeamWork";
import ProjectManagment from "./Sections/ProjectManagment";
import Projects from "./Sections/Projects";

const AboutUs = () => {
  const [targetedQuery] = useTargetedMediaQuery(570);
  return (
    <div className="">
      <div>
        <AboutUsHero />
      </div>
      <div
        className={`w-full max-w-[1820px] m-auto pt-20 pb-20 overflow-hidden`}
      >
        <ProjectManagment />
      </div>

      <div className="w-full bg-[#00235a] m-auto overflow-hidden">
        <Projects />
      </div>
      <div
        className={`bg-orangeTheme p-20 pl-40 pr-40
      ${targetedQuery && "pl-2 pr-2"}
      `}
      >
        <BuildingAboutUs />
      </div>

      <div className="w-full max-w-[1820px] m-auto pt-20 pb-20">
        <EfectiveTeamWork />
      </div>
    </div>
  );
};

export default AboutUs;
