import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonMiniPlus from "../../Buttons/ButtonMiniPlus";

class calculateMouseMovment {
  constructor(acceptedActionRadius) {
    this.mouseUpCoordinates = [0, 0];
    this.mouseDownCoordinates = [0, 0];
    this.acceptedActionRadius = acceptedActionRadius;
  }

  mouseUpSet(x, y) {
    this.mouseUpCoordinates = [x, y];
  }
  mouseDownSet(x, y) {
    this.mouseDownCoordinates = [x, y];
  }
  mouseAction() {
    const mouseRadius = (c1, c2) => {
      return Math.sqrt(
        (c1[0] - c2[0]) * (c1[0] - c2[0]) + (c1[1] - c2[1]) * (c1[1] - c2[1])
      );
    };
    if (
      mouseRadius(this.mouseUpCoordinates, this.mouseDownCoordinates) <
      this.acceptedActionRadius
    ) {
      return true;
    }
    return false;
  }
}


const OurProjectsCard = ({
  color,
  headerTitle,
  title,
  servicePro,
  link,
  photo,
}) => {
  const [hover, setHover] = useState(false);
  const colorOrange = "#ff5e14";
  let navigate = useNavigate();
  const [calculateSlider, setCalculateSlider] = useState(new calculateMouseMovment(5))
  

  const handelMouseup = (e) => {
    calculateSlider.mouseUpSet(e.clientX, e.clientY);
  };

  const handelMouseDown = (e) => {
    calculateSlider.mouseDownSet(e.clientX, e.clientY);
  };

  const handelNavigation = () => {
    if(calculateSlider.mouseAction()){
      navigate(link)
    }
  };
  return (
    <>
      <div
        className={` mr-10 flex flex-col transition-[2s]  h-fit cursor-pointer min-h-[587px] overflow-visible`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{ color: color }}
        onMouseDown={handelMouseDown}
        onMouseUp={handelMouseup}
        onClick={handelNavigation}
      >
        <img
          style={hover ? { opacity: "0.7" } : {}}
          className="w-[550px] max-h-[325px] min-h-[325px] h-[325px] bg-black"
          src={photo}
        ></img>
        <div className=" w-full flex-grow-[1] flex flex-col">
          <span className="mb-2 font-semibold">{headerTitle}</span>
          <h1 className="mb-5 text-3xl font-bold">{title}</h1>
          <p className="mb-5   break-words overflow-hidden font-semibold text-lg">
            {servicePro}
          </p>
          <div className="flex items-center mt-auto">
            <ButtonMiniPlus backgroundColor={`${hover ? colorOrange : ""}`}>
              +
            </ButtonMiniPlus>
            <span className={`mr-2 font-semibold   ml-2`}>Lexo më shumë</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurProjectsCard;
