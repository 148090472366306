



import React, { useEffect, useRef, useState } from "react";
import UseMediaQueryHook from "../../../Hooks/mediaQuery";

import EffectiveTeamwork from "../../../Assets/aboutUs/Effective Teamwork.jpg";
import useTargetedMediaQuery from "../../../Hooks/useTargetedMediaQuery";
import useOnScreen from "../../../Hooks/useOnScreen";

const ProgresBar = ({ Progres, title, progresnumber }) => {
  const [targetedQuery] = useTargetedMediaQuery(500);
  const [NumberP, setNumberP] = useState(0);
  useEffect(() => {
    if (NumberP < Progres)
      setTimeout(() => {
        setNumberP((n) => n + 1);
        return () => {
          clearTimeout();
        };
      }, 20);
  }, [NumberP]);
  return (
    <>
      <div>
        <h4 className="font-semibold mb-2">{title}</h4>
        <div className="w-full bg-gray-200 h-1.5 dark:bg-gray-700">
          <div
            className="bg-orangeTheme h-1.5 flex justify-end"
            style={{ width: `${NumberP}%` }}
          >
            <span className="relative bottom-7 left-4">{`${NumberP}%`}</span>
          </div>
        </div>
      </div>
    </>
  );
};

const EfectiveTeamWork = () => {
  const [specialQuery] = UseMediaQueryHook(1000, 1200, 1280);
  const [targetedQuery] = useTargetedMediaQuery(500);
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  return (
    <div
      className={`flex max-w-[1820px]  m-auto pl-10 pr-10 justify-center 
      ${targetedQuery && "pl-1 pr-1"}
      ${specialQuery == 1000 && "flex-col max-w-none"}`}
    >
      <img
        src={EffectiveTeamwork}
        className={`w-[500px] ${
          specialQuery == 1000 && "w-[620px] h-auto m-auto object-scale-down"
        }`}
      />
      <br />
      <br />
      <div className=" flex">
        <div
          className={`ml-10  mt-auto mb-auto max-w-md sm:max-w-[90%] md:max-w-[500px] w-[500px] pr-5 text-gray-700`}
        >
          <h4 className="font-bold "></h4>
          <h1 className=" max-w-[87%] text-5xl font-bold text-BlueTheme">
            Tregues Suksesi
          </h1>
          <br />
          <div className="w-full h-[150px] flex flex-col gap-5" ref={ref}>
            {isVisible && (
              <>
                {" "}
                <ProgresBar Progres={100} title={"Siguria"} progresnumber={0} />
                <ProgresBar Progres={99} title={"Cilësia"} progresnumber={1} />
                <ProgresBar Progres={95} title={"Performanca"} progresnumber={2} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EfectiveTeamWork;
