import React from "react";
import Slider from "react-slick";
import BannerTitlePart from "../../../Components/BannerTitlePart/BannerTitlePart";
import OurProjectsCard from "../../../Components/Cards/ourProjectsCard/OurProjectsCard";
import OurProjectsSlider from "../../../Components/Sliders/OurProjects/OurProjectsSlider";
import ProjectsData from "../../../Data/Projects/ProjectList";
import { useMobile } from "../../../Hooks/useMobile";

const Projects = () => {

  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  return (
    <div>
      <OurProjectsSlider />
    </div>
  );
};

export default Projects;
