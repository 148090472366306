import { useEffect, useState } from "react";

const useTargetedMediaQuery = (ResTarget) => {
  const [resolution, setResolution] = useState();

  const handleResize = () => {
    if (window.innerWidth < ResTarget) {
      setResolution(true);
    } else {
      setResolution(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return [resolution];
};

export default useTargetedMediaQuery;
