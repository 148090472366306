import React from "react";

const EmailStatusCard = ({ loading, emailSent, clearPrompt }) => {
  let PromptDisplay = false;

  if (loading) {
    PromptDisplay = { msg: "Mesazhi është duke u dërguar", color: "white" };
  }

  if (!loading && emailSent.sent && emailSent.hasSent) {
    PromptDisplay = { msg: "Mesazhi u dërgua", color: "green" };
  }

  if (emailSent.hasSent && !emailSent.sent) {
    PromptDisplay = { msg: "Mesazhi nuk u dërgua", color: "red", Clear: true };
  }

  return (
    <>
      {PromptDisplay && (
        <div
          className="w-full h-full flex flex-col justify-center items-center   z-10 absolute"
          style={{ backgroundColor: PromptDisplay.color }}
        >
          {PromptDisplay.Clear && (
            <div
              onClick={clearPrompt}
              className="w-10 flex justify-center items-center cursor-pointer aspect-[1/1] rounded-full bg-[white] text-[red] absolute top-10 left-10"
            >
              X
            </div>
          )}
          <span
            className="w-2/3 text-center text-5xl font-extrabold"
            style={{ color: loading ? "black" : "white" }}
          >
            {PromptDisplay.msg}
          </span>
        </div>
      )}
    </>
  );
};

export default EmailStatusCard;
