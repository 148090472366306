import React from "react";
import Banner from "../../../../Components/PageHeaders/Banner/BannerHero/Banner";
import ServicesHeroData from "../../../../Data/PageHeadersHeroData/ServicesPro/ServicesHeroData";
const ServiceHero = () => {
  return (
    <div>
      <Banner BannerData={ServicesHeroData} />
    </div>
  );
};

export default ServiceHero;
