import React from "react";
import NumberCardProgres from "../../Components/Cards/NumberCardProgres/NumberCardProgres";
import ServiceSectionHome from "../../Components/SharedSections/services/ServiceSectionHome";
import OurProjectsSlider from "../../Components/Sliders/OurProjects/OurProjectsSlider";
import UseMediaQueryHook from "../../Hooks/mediaQuery";
import { useMobile } from "../../Hooks/useMobile";
import useTargetedMediaQuery from "../../Hooks/useTargetedMediaQuery";
import ProjectManagmentOurServices from "../OurProjectsHomePage/Sections/ProjectManagment/ProjectManagmentOurServices";

import IconsSection from "./Sections/Icons/IconsSection";


import ServiceHero from "./Sections/ServiceHero/ServiceHero";

const OurServices = () => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  const [specialHook]=UseMediaQueryHook(100,1057,1285)
  const [targetetQuery]=useTargetedMediaQuery(1370)
  return (
    <div className="max-w-[100vw] overflow-hidden pt-0 pb-0">
      <div>
        <ServiceHero/>
      </div>
      <div>
        <ServiceSectionHome/>
      </div>
      {/* <div className="pt-0 pb-20 max-w-7xl m-auto">
        <IconsSection/>
      </div> */}
      <div className="mb-16 h-[750px] w-[90%] m-auto">
      <ProjectManagmentOurServices/>
      </div>
      <div
        className={`
        m-auto max-w-[1280px] pl-10 pr-10 mb-20 w-fit
grid 
lg:grid-cols-2
xl:grid-cols-3
${!targetetQuery&&"scale-110"}
      `}
      >
        <NumberCardProgres
          titleAndNumberColor={"#001659"}
          // title={"siguia"}
          titleBreak={"Siguria"}
          // number={"23"}
          Info={"Asgjë nuk është më e rëndësishme se siguria e kolegeve tanë."}
          percentP={100}
        />
        <NumberCardProgres
          titleAndNumberColor={"#001659"}
          // title={"performanca"}
          titleBreak={"Performanca"}
          // number={"23"}
          Info={"Çelsi i suksesit është performanca jonë dinjitoze te klientët."}
          percentP={95}
        />
        <NumberCardProgres
          titleAndNumberColor={"#001659"}
          // title={"cilesia"}
          titleBreak={"Cilësia"}
          // number={"23"}
          Info={"Ne synojmë gjithmon perfeksionin."}
          percentP={99}
        />
      </div>
    </div>
  );
};

export default OurServices;
