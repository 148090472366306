
import OurProHomeBanner from "./Photos/OurProHomeBanner.jpg"
import OurProHomeBannerOther from "./Photos/OurProHomeBannerOther.jpg"



const OurProjectsHomeBanner = [
  {
    id: 1,
    title: `Një e ardhme ndryshe me projektet tona`,
    // subtitle: "stbtitle2",
    projection: "PROJEKTE",
    Content: `Ekspertët e Viante janë të gatshëm dhe te afte të bëjnë zgjedhjen më të mirë për suksesin tuaj, duke bashkuar forcat dhe profesionalizmin.`,
    // Button1: { name: "button1", link: "/buton1" },
    // Button2: { name: "button2", link: "/buton2" },
    Photo: OurProHomeBanner,
    LiteModeColors:false,
    darkBackground:true,
  },
  {
    id: 2,
    title: "Një e ardhme ndryshe me projektet tona",
    // subtitle: "stbtitle2",
    projection: "PROJEKTE",
    Content: "Ekspertët e Viante janë të gatshëm dhe te afte të bëjnë zgjedhjen më të mirë për suksesin tuaj, duke bashkuar forcat dhe profesionalizmin.",
    // Button1: { name: "button1", link: "/buton1" },
    // Button2: { name: "button2", link: "/buton2" },
    Photo: OurProHomeBannerOther,
    LiteModeColors:false,
    darkBackground:true,
  },
];

export default OurProjectsHomeBanner;
