import { useCallback, useEffect, useState } from "react";

const breakpoints = {
  mobile: 639,
  tablet: 767,
  desktopLow: 1200,
  desktopLarge: 1920,
};
/**
 * Hook to define the device layout view
 * @returns Functionalities object
 */
export const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktopLow, setIsDesktopLow] = useState(false);
  const [isDesktopLarge, setIsDesktopLarge] = useState(false);

  const handleDevice = useCallback(() => {
    setIsMobile(window.innerWidth < breakpoints.mobile);
    setIsTablet(
      window.innerWidth >= breakpoints.mobile &&
        window.innerWidth < breakpoints.desktopLow
    );
    setIsDesktopLow(window.innerWidth >= breakpoints.desktopLow);
    setIsDesktopLarge(window.innerWidth >= breakpoints.desktopLarge);
  }, []);
  useEffect(() => {
    handleDevice();
    window.self.addEventListener("resize", handleDevice);
    return () => window.self.removeEventListener("resize", handleDevice);
  }, []);
  return {
    isMobile,
    isTablet,
    isDesktopLow,
    isDesktopLarge,
  };
};
