import React from "react";
import efectiveBuilding from "../../../Assets/aboutUs/Building/efectiveBuilding.png";
import financialresaults from "../../../Assets/aboutUs/Building/financial resaults.png";
import generalConstructing from "../../../Assets/aboutUs/Building/generalConstructing.png";
import UseMediaQueryHook from "../../../Hooks/mediaQuery";
import useTargetedMediaQuery from "../../../Hooks/useTargetedMediaQuery";

const BuildingAboutUsCard = ({ image, title, info }) => {
  return (
    <div className="flex  text-white w-full lg:w-2/3 xl:1/3 ">
      <img src={image} className={`object-scale-down mb-auto`} />

      <div className="ml-5">
        <br />

        <h1 className="text-2xl font-bold">{title}</h1>
        <br />
        <p>{info}</p>
      </div>
    </div>
  );
};

const BuildingAboutUs = () => {
    const [specialQuery] = UseMediaQueryHook(20, 700, 1200);

    const [targetedQueryPro]=useTargetedMediaQuery(1200)
  return (
    <div className={`flex my-10 max-w-[1280px] m-auto gap-5
    ${targetedQueryPro&&"flex-col"}
    `}>
      <BuildingAboutUsCard
        image={efectiveBuilding}
        title={"SIGURI"}
        info={`
        Siguria dhe mirëqenia e ekipit ,partnerëve tanë dhe publikut është përgjegjësia jonë më e madhe.
      `}
      />
      <BuildingAboutUsCard
        image={financialresaults}
        title={"RISI"}
        info={`
        Ne gjithmonë synojmë performancë të lartë, inovacion dhe cilësi në të gjitha aspektet e punës sonë.
      `}
      />
      <BuildingAboutUsCard
        image={generalConstructing}
        title={"INTEGRITET"}
        info={`
        Transparenca dhe ndershmëria karakterizon këdo në kompaninë tonë.
      `}
      />
    </div>
  );
};

export default BuildingAboutUs;
