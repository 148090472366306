import React from "react";
import { Link } from "react-router-dom";
import services from "../../Data/ServiceData/ServiceData.json";
const OurServicesFooter = () => {


  return (
    <div className="  pl-5 pr-5 text-gray-200">
      <h1 className="text-3xl font-bold mb-2 text-white">Shërbimet tona</h1>
      <div className="flex flex-col">
      {services.map((s)=>
      <Link to={"/OurServices"} key={s.id} className="mb-2 hover:text-orangeTheme ">
          {s.headerTitle}
      </Link>
      )}
      </div>
    </div>
  );
};

export default OurServicesFooter;
