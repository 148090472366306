import React from "react";
import { useMobile } from "../../Hooks/useMobile";
import ButtonN2Link from "../Buttons/ButtonN2Link";

const ContentCard = ({
  colorTheme,
  headerTopTitle,
  headerTitle,
  Content,
  buttonTitle,
  linkTo,
  contentSize,
}) => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  return (
    <>
      <div
        className={`
        ${
          colorTheme == "orange"
            ? `${
                isTablet
                  ? "p-[45px] scale-105 mt-auto mb-auto max-w-md sm:max-w-[90%]"
                  : "p-[45px] mt-auto mb-auto max-w-md sm:max-w-[90%] md:max-w-[500px]"
              }`
            : "m-auto p-[45px] mt-auto mb-auto max-w-md sm:max-w-[90%] md:max-w-[500px]"
        } 
        ${colorTheme != "orange" ? "p-4 ml-10" : "p-16"}
        ${isMobile && "pr-1 pl-1 ml-1 mr-1"}
        `}
        style={{
          backgroundColor: colorTheme == "orange" && "#ff5e14",
          color: colorTheme == "orange" ? "white" : "grey",
        }}
      >
        <h4 className="font-bold ">{headerTopTitle}</h4>
        <h1
          className=" mb-[20px] mt-[5px] text-[40px] leading-[.96em] tracking-[-.02em] font-[600] break-words  max-w-[87%]"
          style={{ color: colorTheme != "orange" && "#001659" }}
        >
          {headerTitle}
        </h1>
        {contentSize
        ?
        <p className="cardContentContent max-w-[90%] text-base break-words" style={{fontSize:contentSize+"px"}}> {Content}</p>
        :
        <p className="cardContentContent max-w-[90%] text-sm break-words"> {Content}</p>
        }
        
        <br />
        {colorTheme != "orange" && (
          <ButtonN2Link
            backgroundColor={"#ff5e14"}
            textColor={"white"}
            linkTo={linkTo}
            color={"white"}
          >
            {buttonTitle}
          </ButtonN2Link>
        )}
        {colorTheme == "orange" && (
          <ButtonN2Link
            backgroundColor={"white"}
            textColor={"#ff5e14"}
            linkTo={linkTo}
            color={"#00235a"}
          >
            {buttonTitle}
          </ButtonN2Link>
        )}
      </div>
    </>
  );
};

export default ContentCard;
