import React, { useEffect, useState } from "react";

const CircularProgressBar=(props)=> {

    // Size of the enclosing square
    const sqSize = props.sqSize;
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (props.sqSize - props.strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - (dashArray * props.percentage) / 100;
    return (
      <svg
        width={props.sqSize}
        height={props.sqSize}
        viewBox={viewBox}
      >
        <circle
          className="stroke-[#ddd] fill-transparent"
          cx={props.sqSize / 2}
          cy={props.sqSize / 2}
          r={radius}
          strokeWidth={`${props.strokeWidth}px`}
        />
        <circle
          className="fill-transparent stroke-orangeTheme"
          cx={props.sqSize / 2}
          cy={props.sqSize / 2}
          r={radius}
          strokeWidth={`${props.strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${props.sqSize / 2} ${
            props.sqSize / 2
          })`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
        <text
          className="font-bold text-[45px] fill-BlueTheme"
          x="52%"
          y="52%"
          dy=".3em"
          textAnchor="middle"
        >
          {`${props.percentage}%`}
        </text>
      </svg>
    );
}

// CircularProgressBar.defaultProps = {
//   sqSize: 100,
//   percentage: 25,
//   strokeWidth: 5,
// };

const ProgresBarInicialFunction =(props)=> {


  return (
    <div>
      <CircularProgressBar
        strokeWidth="5"
        sqSize={props.strokeSize}
        percentage={props.percent}
      />
      <div>
      </div>
    </div>
  );
}
const NumberBarPart = ({ percent }) => {
  const [NumberP, setNumberP] = useState(0);
  useEffect(() => {
    let timeoutCount
    if (NumberP < percent){
      timeoutCount= setTimeout(() => {
 
         setNumberP((n) => n + 1);
       }, 50);

    }
      return () => {
        clearTimeout(timeoutCount);
      };
    }, [NumberP]);
  return <NumberCorection percent={NumberP}/>;
};

const NumberCorection = ({percent}) => {
  return <ProgresBarInicialFunction percent={percent} strokeSize={"150"} />;
};

export default NumberBarPart;
