import React from "react";
import { useMobile } from "../../../../Hooks/useMobile";
import OtherProjectsCard from "./OtherProjectsCard/OtherProjectsCard";

const OtherProjects = ({ ProjectList }) => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();

  return (
    <div className=" max-w-[1280px] m-auto p-10">
      <h1 className="text-[35px] font-bold text-BlueTheme mb-5">
        Projekte Të Tjera
      </h1>
      <div className={`grid gap-5
      ${isDesktopLow&&"grid-cols-4"}
      ${isTablet&&"grid-cols-2"}
      ${isMobile&&"grid-cols-1"}
      `}>
        {ProjectList.map((n) => (
          <OtherProjectsCard 
          key={n.id}
          Project_Page_link_id={n.Project_Page_link_id}
          photo={n.photo}
          subTitle={n.subTitle}
          title={n.title}
          />
        ))}
      </div>
    </div>
  );
};

export default OtherProjects;
