import React from "react";
import OurProjectsSlider from "../../Components/Sliders/OurProjects/OurProjectsSlider";
import FullProjectManagmint from "./Sections/FullProjectManagment/FullProjectManagmint";
import Hero from "./Sections/Hero/Hero";

import NumbersSection from "./Sections/Numbers/NumbersSection";
import ProjectManagmentOurServices from "./Sections/ProjectManagment/ProjectManagmentOurServices";

const OurProjectsHomePage = () => {
  return (
    <div>
      <div>
        <Hero />
      </div>
      <div className=" pt-20 pb-5">
        <div className="pt-20 pb-16 ">
          <FullProjectManagmint />
        </div>
        <div className="mb-16 h-[750px] w-[90%] m-auto">
          <ProjectManagmentOurServices/>
        </div>
        <div className="bg-darkBlueTheme m-auto pt-20 pb-0 mb-5 overflow-x-hidden">
          <OurProjectsSlider />
        </div>
        <div className="max-w-[1800px] m-auto pt-0 pb-0">
          <NumbersSection />
        </div>
      </div>
    </div>
  );
};

export default OurProjectsHomePage;
