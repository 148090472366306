import React, { useEffect } from "react";
import contacts from "../../../Data/contactData/ContactInfo";
import contactsMedia from "../../../Data/contactData/ContactMedia/ContactMedia";
import { useMobile } from "../../../Hooks/useMobile";
import GreydBackground from "./GreydBackground";

const OfficeContact = ({ image, contactInfo, type, LinkInfo }) => {
  return (
    <div className="transition duration-150 hover:transition hover:duration-150">
      <a
        href={!LinkInfo ? `${type}:${contactInfo}` : LinkInfo}
        className="flex hover:text-orangeTheme"
      >
        <img
          src={image}
          alt=""
          className="object-scale-down aspect-[1/1] mb-auto mt-[4px] w-[18px]"
        />
        <span className="ml-[15px]">{contactInfo}</span>
      </a>
    </div>
  );
};

const ContactPart = ({ Svg, linkTo }) => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  return (
    <a
      href={linkTo}
      className={`h-[75px] w-[75px] max-w-[80px] border-[1px] border-gray-400/50 flex m-auto hover:bg-orangeTheme
    `}
    >
      <span className="mt-auto mb-auto m-auto w-5 h-5">
        <img className="w-full" src={Svg} />
      </span>
    </a>
  );
};

const NavBarInfoMenu = ({ ActiveInfo, setMenuActiveInfo, opening }) => {
  return (
    <>
      <GreydBackground
        ActiveInfo={ActiveInfo}
        setMenuActiveInfo={setMenuActiveInfo}
      />
      <div
        className={`max-h-[100vh] overflow-y-scroll scrollbar md:overflow-y-auto duration-500 z-50 h-[100vh] overflow-hidden absolute top-0 right-0 text-white  ${
          ActiveInfo ? "w-96" : "w-0"
        }`}
      >
        <div className="bg-[#0a1229] w-96 min-h-full p-10 font-semibold">
          <div
            className="h-16 w-16 flex bg-[#ff5e14] relative top-0 left-[275px]"
            onClick={() => setMenuActiveInfo((h) => !h)}
          >
            <div className="w-full h-full flex p-[25%] flex-col justify-center items-center z-0 font-bold cursor-pointer select-none text-white">
              X
            </div>
          </div>
          <div className="pt-10">
            <span className="text-2xl font-bold block mb-4">Na kontaktoni</span>
            <p className="w-7/9 break-words text-[#9ca5af]">
              Viante Konstruksion është i përbërë nga një staf profesionistësh.
              Ekipi ynë do ju vijë në ndihmë në çdo moment për çdo pyetje.
              Kontaktoni më poshtë:
            </p>
          </div>
          <br />
          <br />
          <div className="flex">
            <span className="w-1/12 overflow-hidden">
              <img className="w-5 h-5" src={opening} />
            </span>
            <p className="w-10/12 pb-5    break-words ml-2 text-[#9ca5af] ">
              8:00-17:00 Nga e hëna në të premte
            </p>
          </div>
          <div className="text-[#9ca5af]">
            {/* locations */}
            <OfficeContact
              image={contacts.location.locationIcon}
              contactInfo={contacts.location.location}
              LinkInfo={contacts.location.linkToInfo}
            />

            <div className="h-4 w-2" />
            {/* phoneNumber */}
            <OfficeContact
              image={contacts.PhoneNumber.PhoneIcon}
              contactInfo={contacts.PhoneNumber.Pone1}
              type={"tel"}
            />

            <div className="h-4 w-2" />

            {/* Email */}
            <OfficeContact
              image={contacts.EmailAddress.EmailAddressIcon}
              contactInfo={contacts.EmailAddress.Email1}
              type={"mailto"}
            />
            <div className="h-4 w-2" />
            <OfficeContact
              image={contactsMedia[0].icon}
              contactInfo={contactsMedia[0].name}
              LinkInfo={contactsMedia[0].url}
            />
          </div>
          <br />
          <br />
          <br />
          <br />
          <div className="w-fit  flex justify-center align-middle gap-0">
            {/* <ContactPart
              linkTo={contactsMedia[0].url}
              Svg={contactsMedia[0].icon}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBarInfoMenu;
