import React, { useState } from "react";
import QuoteOrange from "../../../Assets/Feedback/QuotesIcon/quoteOrange.svg";
import QuoteBlack from "../../../Assets/Feedback/QuotesIcon/quoteBlack.svg";
import { useMobile } from "../../../Hooks/useMobile";
const FeedBackCard = ({
  profilePhotoTest,
  HeaderAdditional,
  Title,
  feedback,
  index,
  sliderIndex,
  HandelsetpreventHover,
}) => {
  const [hover, setHover] = useState(true);
  const [isHovering, setIsHovering] = useState();
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  if (index == sliderIndex) {
    if (!hover) {
      setHover(true);
    }
  }
  if (index != sliderIndex && hover && !isHovering) {
    setHover(false);
  }
  return (
    <>
      <div
        className={`ml-5 flex h-fit p-7  border-b-4 border-b-orange-500 border-2 transition-[2s] border-gray-200 
      ${hover || isHovering ? "border-none bg-BlueTheme" : ""}
${isMobile && "flex flex-col"}
${!isMobile && "max-h-[275px] min-h-[275px]"}
      `}
        onMouseEnter={() => {
          setIsHovering(true);
          HandelsetpreventHover(index, false);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
          HandelsetpreventHover(index, true);
        }}
      >
        <div className="flex">
          <img
            className={`
            ${!isMobile && "bg-black w-20 h-20 rounded-md mr-4 border-0"}
            ${isMobile && "bg-black w-20 h-20 rounded-md mr-4 border-0 mb-10"}
            `}
            src={profilePhotoTest}
          ></img>
          {isMobile && (
            <span className={` w-10 ml-auto`}>
              <img
                className={`${!hover && !isHovering ? "!block" : "!hidden"}`}
                src={QuoteBlack}
              />

              <img
                className={`${hover || isHovering ? "!block" : "!hidden"}`}
                src={QuoteOrange}
              />
            </span>
          )}
        </div>
        <div className="w-10/12 h-5/6">
          <span
            className={`font-bold  
            ${hover || isHovering ? "text-white" : "text-gray-700"}
            
            `}
          >
            {HeaderAdditional}
          </span>
          <h1
            className={`text-4xl font-bold  mb-4
          ${hover || isHovering ? "text-white" : ""}`}
          >
            {Title}
          </h1>
          <p
            className={`text-lg font-normal  overflow-hidden break-words max-h-[170px] ${
              hover || isHovering ? "text-white" : "text-gray-700"
            }`}
          >
            {feedback}
          </p>
        </div>
        <div
          className={`
        ${isMobile && "hidden "}
        ${
          !isMobile && "h-0 w-0 relative top-[0.5rem] left-[-2.05rem] font-bold"
        }
        `}
        >
          <span className={`absolute w-10`}>
            <img
              className={`${!hover && !isHovering ? "!block" : "!hidden"}`}
              src={QuoteBlack}
            />

            <img
              className={`${hover || isHovering ? "!block" : "!hidden"}`}
              src={QuoteOrange}
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default FeedBackCard;
