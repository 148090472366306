import photo1 from "./photos/1.jpg";
import photo2 from "./photos/2.jpg";

const HomeBannerData = [
  {
    id: 1,
    title: `... ne ndertojmë të ardhmen tuaj`,
    // subtitle: "stbtitle2",
    projection: "VIANTE",
    Content: `Shoqëria “VIANTE KONSTRUKSION” shpk, ka objektiva ambicioze për plotësimin e standarteve bashkohore,në përputhje me normat dhe kodet europiane në fushën e ndërtimit.Ne gjithmon garantojmë në punimet tona një cilësi të lartë të materialeve standarte të certifikuara ISO të importit ,si dhe aftësinë, përkushtimin dhe përgjegjesinë e pjestarëve të stafit tonë për të ndërtuar konform këtyre standarteve.`,
    Button1: { name: "Rreth nesh", link: "/AboutUs" },
    Button2: { name: "Na kontaktoni", link: "/contactUs" },
    Photo: photo2,
    LiteModeColors:false,
    darkBackground:true,
  },
  // {
  //   id: 2,
  //   title: "BUILD A BETTER TOMORROW",
  //   // subtitle: "stbtitle2",
  //   projection: "PROJECTION2",
  //   Content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis Theme natoque penatibus et magnis dis parturient montes, nascetur.",
  //   Button1: { name: "button1", link: "/buton1" },
  //   Button2: { name: "button2", link: "/buton2" },
  //   Photo: photo2,
  //   LiteModeColors:true,
  // },
];

export default HomeBannerData;
