import facebook from "./MediaIcons/facebook.svg";
import facebookOrange from "./MediaIcons/facebookOrange.svg";

const contactsMedia = [
  {
    id: 1,
    name: "facebook",
    url: "https://www.facebook.com/viantekonstruksion.shpk",
    icon: facebook,
    iconHover: facebookOrange,
  },
];
export default contactsMedia