import React from "react";
import { useMobile } from "../../../../Hooks/useMobile";
import ProjectPageSlider from "./PageProjectsSlider/ProjectPageSlider";

const PhotoSliderAndContent = ({ pageData }) => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  const pageDataObj = pageData;
  return (
    <>
      <div className="pt-20">
        <ProjectPageSlider photosArray={pageDataObj.Photos} />
      </div>
      <div
        className={`sm:w-[70%] w-[95%] max-w-[1280px] m-auto flex pt-10 pb-10
      ${isDesktopLow && ""}
      ${isTablet && "flex-col"}
      ${isMobile && "flex-col"}
      `}
      >
        <div
          className={`pr-4
      ${isDesktopLow && "w-[70%]"}
      ${isTablet && "w-full"}
      ${isDesktopLow && "w-full"}
        
        `}
        >
          <h1
            className={`
          sm:text-[40px] font-semibold text-BlueTheme text-[30px] break-words
          `}
          >
            {pageDataObj.ProjectPageSection.title}
          </h1>
          <p className="break-words w-[80%] text-[15px] sm:text-base">
            {pageDataObj.ProjectPageSection.ProjectDescription}
          </p>
        </div>
        <div>
          <h1 className="text-[30px] font-semibold text-BlueTheme mb-2">
          Projekt Info
          </h1>
          <p className=""><span className="font-bold">KATEGORIA:</span> {pageDataObj.ProjectInfo.category}</p>
          <p><span className="font-bold">DATA:</span> {pageDataObj.ProjectInfo.Date}</p>
          <p><span className="font-bold">INVESTITOR:</span> {pageDataObj.ProjectInfo.Taggs}</p>
        </div>
      </div>
    </>
  );
};

export default PhotoSliderAndContent;
