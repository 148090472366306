import React, { useState } from "react";

import { useMobile } from "../../../Hooks/useMobile";

import NavItemsMobile from "./NavItemsMobile";
import NavItemsDesktop from "./NavItemsDesktop";
import opening from "../../../Assets/Icon/sidebar/footer-icon-img-04.png";
import NavBarInfoMenu from "./NavBarInfoMenu.js";

import vianteLogo from "../../../Assets/VianteLogo/viante logo.png";
import { Link } from "react-router-dom";

const NavBar = () => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();

  const [munuActive, setMenuActive] = useState(false);
  const [ActiveInfo, setMenuActiveInfo] = useState(false);
  return (
    <div className="h-16 flex  justify-between sticky top-0 z-50 bg-white">
      <Link
        to={"/"}
        className={`h-full flex flex-col  w-72 bg-[#ff5e14]  md:bg-black sm:bg-red-700
        ${isMobile && "!w-auto"}
        `}
      >
        <div
          className={`logo w-full h-full flex bg-orangeTheme 
      ${isMobile && "h-full w-52 m-auto"} 
      ${isTablet && "h-full m-auto"} 
      ${isDesktopLow && "h-full"}`}
        >
          <span className="mt-auto mb-auto m-auto flex text-white text-2xl font-semibold">
            Viante
          </span>
          {/* <img className="h-full w-full" src={vianteLogo}/> */}
        </div>
      </Link>
      {isMobile && (
        <>
          <div
            className="h-full w-16 flex bg-[#ff5e14] cursor-pointer"
            onClick={() => setMenuActive((h) => !h)}
          >
            <div className="flex-col justify-between items-center pr-[20px] pl-[20px] pt-[25px] pb-[25px] z-0 transition-all duration-[0.5s] w-[64px] h-[64px] flex p-[25%]">
              <span
                className={`h-[2px] w-1/2 block bg-white transition-all duration-[0.5s] self-start ${
                  munuActive && "!w-full"
                }`}
              ></span>
              <span className={`h-[2px] w-full block bg-white`}></span>
              <span
                className={`h-[2px] w-1/2 block bg-white transition-all duration-[0.5s] self-end ${
                  munuActive && "!w-full"
                }`}
              ></span>
            </div>
          </div>
          <div
            className={`w-full max-h-[500px] duration-500 z-50 overflow-hidden absolute top-[64px] bg-white ${
              munuActive ? "max-h-[100vh] h-screen" : "h-0"
            }`}
          >
            <NavItemsMobile
              ulClassName={`"w-3/4  m-auto mt-5"`}
              setMenuActive={setMenuActive}
              munuActive={munuActive}
            />
          </div>
        </>
      )}

      {!isMobile && (
        <>
          <NavItemsDesktop />

          <div
            className="h-16 w-fit flex cursor-pointer"
            onClick={() => setMenuActiveInfo((h) => !h)}
          >
            <div className="flex-col justify-between items-center pr-[20px] pl-[20px] pt-[25px] pb-[25px] z-0 transition-all duration-[0.5s] w-[64px] h-[64px] flex p-[25%] bg-[#ff5e14]">
              <span
                className={`h-[2px] w-1/2 block bg-white transition ease-in-out duration-[0.3s] self-start ${
                  munuActive && "activeBars"
                }`}
              ></span>
              <span className={`h-[2px] w-full block bg-white`}></span>
              <span
                className={`h-[2px] w-1/2 block bg-white transition-all duration-[0.5s] self-end ${
                  munuActive && "activeBars"
                }`}
              ></span>
            </div>
          </div>
          {/* //nav menu */}
          <NavBarInfoMenu
            ActiveInfo={ActiveInfo}
            setMenuActiveInfo={setMenuActiveInfo}
            opening={opening}
          />
        </>
      )}
    </div>
  );
};

export default NavBar;
