import React from "react";
import { useMobile } from "../../Hooks/useMobile";
import useTargetedMediaQuery from "../../Hooks/useTargetedMediaQuery";
import BuildWithFooter from "./BuildWithFooter";
import FooterCoppyRight from "./FooterCoppyRight";
import OurLocations from "./OurLocations";
import OurOfices from "./OurOfices";
import OurServicesFooter from "./OurServicesFooter";

const Footer = () => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  const [targetPro]=useTargetedMediaQuery(500)
  return (
    <div className="overflow-hidden">
      <div className="bg-[#0a1229] pt-10 mt-auto mb-0">
        <div
          className={`max-w-[1280px] grid mt-10 mb-10 m-auto 
      ${isMobile && "grid-cols-1 gap-5 pl-5 pr-5"} 
      ${isTablet && "grid-cols-2 gap-5 p-5"}
      ${isDesktopLow && "grid-cols-4"}
      `}
        >
          <BuildWithFooter />
          <OurServicesFooter />
          <OurOfices />
          <OurLocations />
        </div>
        <div className={`${!targetPro&&"pr-20 pl-20 max-w-[1280px] m-auto"}
        ${targetPro&&"pr-0 pl-0 w-[90vw] m-auto"}
        `}>
          <FooterCoppyRight />
        </div>
      </div>
    </div>
  );
};

export default Footer;
