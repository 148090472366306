import { useEffect } from "react";



const GreydBackground = ({ ActiveInfo, setMenuActiveInfo ,disableBackDrop}) => {
    useEffect(() => {
      const scrollBarWidth =
        window.innerWidth - parseInt(window.visualViewport.width);
      if (ActiveInfo) {
        document.body.style.overflow = "hidden";
        document.body.style.paddingRight = scrollBarWidth + "px";
      } else {
        document.body.style.overflow = "initial";
        document.body.style.paddingRight = "0px";
      }
      return () => {};
    }, [ActiveInfo]);
    return (
      <>
        {ActiveInfo && (
        !disableBackDrop&&  <div
            onClick={() => setMenuActiveInfo(false)}
            style={{animationFillMode:"forwards"}}
            className="w-full h-screen absolute bg-black z-50 animate-BackDropMenu"
          ></div>
        )}
      </>
    );
  };

  export default GreydBackground