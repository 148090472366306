import React from "react";

const FullProjectMgrCard = ({photo,subtitle,title,info}) => {
  return (
    <div className=" p-4">
      <img src={photo} className={` h-16 w-16`}></img>
      <br/>
      <div className="w-10/12 h-5/6">
        <span className={`font-bold text-gray-700`}>{subtitle}</span>
        <h1 className={`text-xl font-bold text-BlueTheme`}>{title}</h1>
        <p
          className={`text-base font-normal text-gray-700 overflow-hidden break-words max-h-[250px]`}
        >
         {info}
        </p>
      </div>
    </div>
  );
};

export default FullProjectMgrCard;
