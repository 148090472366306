import React from "react";
import { useMobile } from "../../Hooks/useMobile";
import useTargetedMediaQuery from "../../Hooks/useTargetedMediaQuery";
import ContactForm from "./Sections/Form/ContactForm";
import ContactInfo from "./Sections/Info/ContactInfo";


const ContactUs = () => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  const [queryTarget]=useTargetedMediaQuery(500)






  return (
    <div className=" m-auto">

      <div
        className={`
      
      ${isDesktopLow && "grid grid-cols-2"}
      ${isTablet && "flex flex-col-reverse"}
      ${isMobile && "flex flex-col-reverse"}
      `}
      >
        <div className="">
          <ContactForm />
        </div>
        <div className="">
          <ContactInfo />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
