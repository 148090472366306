import { useEffect, useState } from "react";

const useRangedMediaQuery = (low, high) => {
  const [QueryMedia, setQueryMedia] = useState([undefined, undefined, undefined]);


  const handleResize = () => {




    setQueryMedia((QueryMedia) => {

      
      if (parseInt(window.visualViewport.width)< low) {
        if (!QueryMedia[0]) {
          return [true, false, false];
        }
        else {
          return QueryMedia
        }
      }
      if ((parseInt(window.visualViewport.width)>= low) && (parseInt(window.visualViewport.width)<= high)) {
        if (!QueryMedia[1]) {
          return [false, true, false];
        }
        else {
          return QueryMedia
        }
      }
      if (parseInt(window.visualViewport.width)> high) {
        if (!QueryMedia[2]) {
          return [false, false, true];
        }
        else {
          return QueryMedia
        }
      }
      return QueryMedia
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return [QueryMedia[0], QueryMedia[1], QueryMedia[2]];
};

export default useRangedMediaQuery;
