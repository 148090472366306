import React, { useState } from "react";
import Slider from "react-slick";
import { useMobile } from "../../../../Hooks/useMobile";
import HeroBannerPart from "../HeroBanerPart/HeroBannerPart";



function BannerArrowNext(props) {
  const { className, style, onClick } = props;
  return (
    <div

      className={`bg-orangeTheme !flex items-center justify-center h-[73px] opacity-100 p-[15px] absolute w-[70px] z-10 left-[75px] bottom-[10px]
      after:opacity-100 after:text-[30px] after:text-white after:block after:content-['>'] after:font-extrabold after:font-mono hover:bg-orangeTheme ${props.hoverPrev&&"after:!text-black"}`}
      style={{ ...style, display: "block",backgroundColor:props.hoverPrev&&"white" ,color:props.hoverPrev&&"black" }}
      onClick={onClick}
    />
  );
}
function BannerArrowPrev(props) {
  const { className, style, onClick } = props;
  return (
    <div
    onMouseEnter={()=>props.setHoverPrev(true)}
    onMouseLeave={()=>props.setHoverPrev(false)}
      className={`bg-white !flex items-center justify-center h-[73px] opacity-100 p-[15px] absolute w-[70px] z-10 bottom-[10px]
      after:opacity-100 after:text-[30px] after:text-gray-900 after:block after:content-['<'] after:font-extrabold after:font-mono hover:bg-orangeTheme after:hover:text-white
      `}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}


const Banner = ({ BannerData, bannerMini }) => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  const [hoverPrev,setHoverPrev]=useState()


const settings = {
  dots: true,
  fade: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <BannerArrowNext setHoverPrev={setHoverPrev} hoverPrev={hoverPrev}/>,
  prevArrow: <BannerArrowPrev setHoverPrev={setHoverPrev} hoverPrev={hoverPrev}/>,
};


  return (
    <div
      className={` w-screen h-[90vh] min-h-[700px] m-0 p-0 max-w-full overflow-hidden
    ${bannerMini && "h-[70vh]"}
    `}
    >
      <Slider {...settings} className="w-full h-full">
        {BannerData.map((n) => (
          <div key={n.id} className={`w-full h-[90vh] min-h-[700px] ${n.darkBackground&&"bg-black/50"}`}>
            <img
              src={n.Photo}
              className={`absolute w-full h-full object-cover -z-10`}
            />
            <div
              className={`mt-[7%] ml-[10%] text-white
            ${isMobile && "ml-[2%] m-1 p-0"}
            `}
            >
              <HeroBannerPart
                title={n.title}
                subtitle={n.subtitle}
                projection={n.projection}
                Content={n.Content}
                Button1={n.Button1}
                Button2={n.Button2}
                LiteMode={n.LiteModeColors}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Banner;
