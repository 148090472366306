import React from "react";
import GreydBackground from "./GreydBackground";
import NavLinkNavBar from "./NavLinkNavBar";

const NavItemsMobile = ({ulClassName,liClassName,setMenuActive,munuActive}) => {
  return (
    <ul className={""}>
            <GreydBackground
        ActiveInfo={munuActive}
        setMenuActiveInfo={setMenuActive}
        disableBackDrop={true}
      />
      <li className={"navbarMobile"} onClick={()=>setMenuActive(p=>!p)}>
        <NavLinkNavBar
          to="/"
        >
          Home
        </NavLinkNavBar>
      </li>
      <li onClick={()=>setMenuActive(p=>!p)} className={"navbarMobile"}>
        <NavLinkNavBar
          to="/AboutUs"
        >
          Rreth nesh
        </NavLinkNavBar>
      </li>
      <li onClick={()=>setMenuActive(p=>!p)} className={"navbarMobile"}>
        <NavLinkNavBar
          to="/OurServices"
        >
          Shërbimet
        </NavLinkNavBar>
      </li>
      <li className={"navbarMobile"} onClick={()=>setMenuActive(p=>!p)}>
        <NavLinkNavBar
          to="/OurProjectsHome"
        >
          Projektet
        </NavLinkNavBar>
      </li>
      <li className={"navbarMobile"} onClick={()=>setMenuActive(p=>!p)}>
        <NavLinkNavBar
          to="/contactUs"
        >
          Kontakt
        </NavLinkNavBar>
      </li>
    </ul>
  );
};

export default NavItemsMobile;
