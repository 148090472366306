import React from "react";
import contacts from "../../Data/contactData/ContactInfo";
import contactsMedia from "../../Data/contactData/ContactMedia/ContactMedia";

const OfficeContact = ({ image, contactInfo, type, linkToInfo }) => {
  return (
    <>
      <a
        href={!linkToInfo ? `${type}:${contactInfo}` : linkToInfo}
        className="flex hover:text-orangeTheme"
      >
        <img
          src={image}
          alt=""
          className="object-scale-down mb-auto aspect-[1/1] mt-[4px] w-[18px]"
        />
        <span className="ml-[15px]">{contactInfo}</span>
      </a>
    </>
  );
};

const OurOfices = () => {
  return (
    <div className="  pl-5 pr-5 text-gray-200">
      <div className="">
        <h1 className="text-3xl font-bold mb-2 text-white">Kontaktet</h1>
      </div>

      {/* locations */}
      <OfficeContact
        image={contacts.location.locationIcon}
        contactInfo={contacts.location.location}
        linkToInfo={contacts.location.linkToInfo}
      />

      <div className="h-4 w-2" />

      {/* phoneNumber */}
      <OfficeContact
        image={contacts.PhoneNumber.PhoneIcon}
        contactInfo={contacts.PhoneNumber.Pone1}
        type={"tel"}
      />

      <div className="h-4 w-2" />

      {/* Email */}
      <OfficeContact
        image={contacts.EmailAddress.EmailAddressIcon}
        contactInfo={contacts.EmailAddress.Email1}
        type={"mailto"}
      />

      <div className="h-4 w-2" />

      <OfficeContact
        image={contactsMedia[0].icon}
        linkToInfo={contactsMedia[0].url}
        contactInfo={contactsMedia[0].name}
      />
    </div>
  );
};

export default OurOfices;
