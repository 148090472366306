import React, { useRef } from "react";
import CharacrerAnimation from "../../Helpers/Animation/TextAnim/CharacrerAnimation";
import { useMobile } from "../../Hooks/useMobile";
import useOnScreen from "../../Hooks/useOnScreen";

const BannerTitlePart = ({
  projection,
  subtitle,
  subtitleColor,
  title,
  titleColor,
  description,
  descriptionColor,
  positionProjectionDisplace,
}) => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();

  const ref = useRef();
  const isVisible = useOnScreen(ref);

  return (
    <div className="">
      <div className="z-50">
        <div className="z-20">
          <div className="absolute">
            {subtitle && (
              <span
                style={{ color: subtitleColor }}
                className="text-sm z-20 relative top-16 left-10 font-bold block"
              >
                {subtitle}
              </span>
            )}
          </div>
        </div>
      </div>
      <div
        className={
          positionProjectionDisplace
            ? "scale-110 max-w-xs relative bottom-4 left-56"
            : "relative pb-4 h-[140px] top-[107px] left-[70px] scale-110 max-w-xs"
        }
      >
          <span style={{
              "WebkitTextFillColor":" transparent",
              "WebkitTextStrokeWidth": "1px",
              "WebkitTextStrokeColor": "rgb(222 223 224 / 45%)",
          }} ref={ref} className="absolute min-w-[186px] text-[#f5f6f7] h-[2em] inline-block align-middle font-[600] overflow-hidden text-[145px] top-[-150px] left-[-47px] leading-[1em]">
            <>
              {isVisible&&projection && isDesktopLow&&
              <CharacrerAnimation charAnim={projection} speed={100} />
              }
            </>
          </span>
      </div>
      <h1
        className={`text-7xl text-BlueTheme font-bold w-fit relative left-10 bottom-16
      ${isMobile && "text-4xl break-words w-[70%]"}
      `}
        style={titleColor && { color: titleColor }}
      >
        {title}
      </h1>
      <br />
      {description && (
        <h2
          style={{ color: descriptionColor }}
          className="ml-10 relative bottom-16 max-w-[600px] break-words max-h-[350px] overflow-hidden"
        >
          {description}
        </h2>
      )}
    </div>
  );
};

export default BannerTitlePart;
