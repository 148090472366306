import React from 'react'
import { useMobile } from '../../../Hooks/useMobile';

const FeturePartPhoto = ({photo}) => {
    const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  return (
    <div 
    // className='w-[347px] h-[347px]'
    className={`${isMobile&& "max-h-[940px]"} ${isDesktopLow &&"h-[347px]"}`}
    >
        <img className={`w-full h-full ${isDesktopLow&& "object-cover"}`} src={photo} />
    </div>
  )
}

export default FeturePartPhoto