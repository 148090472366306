import React from "react";
import Slider from "react-slick";
import { useMobile } from "../../../Hooks/useMobile";
import BannerTitlePart from "../../BannerTitlePart/BannerTitlePart";
import OurProjectsCard from "../../Cards/ourProjectsCard/OurProjectsCard";


import ProjectsData from "../../../Data/Projects/ProjectList";

function SampleNextArrow(props) {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  const { className, style, onClick } = props;
  return (
    <div
      className={`bg-orangeTheme  
      ProjectsSliderListButtons
      before:!content-['Next→']   ${isDesktopLow && "top-[170px] left-[-200px]"} ${
        isTablet && "top-[125%] left-[0px]"
      } ${isMobile && "top-[125.20%] left-[0px]"}`}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  const { className, style, onClick } = props;
  return (
    <div
      className={`bg-blue-900  
      ProjectsSliderListButtons
      before:!content-['←Prev'] 
      ${isDesktopLow && " top-[245px] left-[-200px]"} 
      ${
        isTablet && "top-[115%] left-[0px]"
      } 
      ${isMobile && " top-[110.5%] left-[0px] "} `}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    />
  );
}

const settings = {

  infinite: true,
  speed: 500,
  slidesToShow: 3,
  swipeToSlide: true,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 2,
        infinite: true,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        swipeToSlide: true,
      },
    },
  ],
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

const OurProjectsSlider = () => {
  const { isMobile, isTablet, isDesktopLow, isDesktopLarge } = useMobile();
  return (
    <div className="bg-[#00235a]  max-w-[1820px] m-auto">
      <div
        className={`${isDesktopLow && " pt-10 pb-10"} ${
          isTablet && "ml-20 pt-16 pb-36"
        } ${isMobile && "ml-0 pt-5 pb-52 sm:pb-36"}`}
      >
        <div className="ml-0 sm:ml-[100px]">
          <BannerTitlePart
            title={"PROJEKTE"}
            projection={"PROJEKTE"}
            titleColor={"white"}
          />
        </div>
        <div
          className={`w-full 
${isMobile && ""}
${isTablet && ""}
${isDesktopLow && "overflow-hidden"}
  `}
        >
          <div
            className={`
p-10 pt-0 h-fit
${isMobile && "w-full"}
${isTablet && "w-full pb-20"}
${isDesktopLow && " pl-72 w-[125%]"}
`}
          >
            <Slider {...settings}>
              {ProjectsData.map((n) => (
                <OurProjectsCard
                  key={n.id}
                  link={`/OurProjectsPage/${n.Project_Page_link_id}`}
                  headerTitle={n.subTitle}
                  title={n.title}
                  servicePro={n.projectDescription}
                  photo={n.photo}
                  color={"white"}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProjectsSlider;
